@charset "UTF-8";
button {
  cursor: auto;
}
@media screen and (max-width: 1980px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 1366px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 1024px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 960px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 750px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 599px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 667px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 568px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 414px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 375px) {
  button {
    cursor: auto;
  }
}
@media screen and (max-width: 320px) {
  button {
    cursor: auto;
  }
}

body {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  color: #3b3b3b;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5 {
  font-size: 18px;
  line-height: 1.4;
  color: #333;
}

p {
  font-size: 18px;
  line-height: 1.5;
  color: #3b3b3b;
}

/*!
 * Bootstrap  v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.bliderBox {
  position: relative;
  max-height: 250px;
  overflow: hidden;
  transition: all 0.3s ease;
}
@media screen and (max-width: 375px) {
  .bliderBox {
    max-height: 250px;
  }
}
.bliderBox:before {
  display: block;
  content: "";
  height: 125px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 375px) {
  .bliderBox:before {
    height: 125px;
  }
}

.bliderBox.js_isActive {
  max-height: 100%;
}

.bliderBox_triggerGroup {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.btn_group_center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.btn_group_right {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
}

btn_group_left {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .topSectionRecruitmenty_btnGroup {
    display: block;
  }
}

@media screen and (max-width: 599px) {
  .top_panel01_btnGroup {
    width: 100%;
  }
}

.btn01 {
  transition: all 0.3s ease;
  background-color: #595757;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 2px 0 1px;
  min-width: 244px;
  text-align: center;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .btn01 {
    min-width: auto;
    width: 100%;
    font-size: 14px;
    padding: 10px 0 9px;
  }
}
.btn01:active, .btn01:link, .btn01:visited, .btn01:focus {
  color: #fff;
}
.btn01:hover {
  border: 1px solid #595757;
  background-color: #FFFFFF;
  color: #000000;
}

.btn02 {
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 20px 0 18px;
  line-height: 1.5;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 20px;
  color: #0056A2;
}
@media screen and (max-width: 960px) {
  .btn02 {
    font-size: 16px;
  }
}
.btn02:active, .btn02:link, .btn02:visited, .btn02:focus {
  color: #0056A2;
}
.btn02:hover {
  background-color: #0056A2;
  color: #fff;
}

.topSectionRecruitmenty_btn02 {
  margin-bottom: 8px;
}
.topSectionRecruitmenty_btn02:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 750px) {
  .topSectionRecruitmenty_btn02 {
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: auto;
  }
  .topSectionRecruitmenty_btn02:last-child {
    margin-right: 0;
  }
}

.btn03 {
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  padding: 12px 0 10px;
  background-color: #FFFFFF;
  text-align: center;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 20px;
  color: #595757;
}
@media screen and (max-width: 960px) {
  .btn03 {
    font-size: 15px;
  }
}
.btn03:active, .btn03:link, .btn03:visited, .btn03:focus {
  color: #595757;
}
.btn03:hover {
  transition: all 0.3s ease;
  background-color: #FFF100;
}

.topSectionRecruitmenty_telBtn {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 8px;
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_telBtn {
    line-height: 1.1;
    padding: 6px 0 5px;
  }
}

.topSectionRecruitmenty_contactBtn {
  position: relative;
  z-index: 1;
  padding: 18px 0 16px;
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_contactBtn {
    padding: 9px 0 8px;
  }
}

.btn04 {
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  padding: 12px 0 10px;
  border: 1px solid #00A0E9;
  background-color: #FFFFFF;
  text-align: center;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 20px;
  color: #00A0E9;
}
@media screen and (max-width: 960px) {
  .btn04 {
    font-size: 15px;
  }
}
.btn04:active, .btn04:link, .btn04:visited, .btn04:focus {
  color: #00A0E9;
}
.btn04:hover {
  transition: all 0.3s ease;
  background-color: #00A0E9;
  border: 1px solid transparent;
  color: #fff;
}

.recruit_btn {
  position: relative;
  width: 20%;
  max-width: 240px;
  background-color: #00A0E9;
}
.recruit_btn a {
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  padding: 32px 0;
  font-size: 20px;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  text-decoration-color: #fff;
  font-weight: normal;
  text-align: center;
  line-height: 1.1;
  letter-spacing: 0.3em;
}
.recruit_btn a:active, .recruit_btn a:link, .recruit_btn a:visited, .recruit_btn a:focus {
  color: #FFFFFF;
}
.recruit_btn a:hover {
  transition: all 0.3s ease;
  opacity: 0.7;
}

.wrapper {
  margin-top: 90px;
}
@media screen and (max-width: 414px) {
  .wrapper {
    margin-top: 60px;
  }
}
.home .wrapper {
  margin-top: 0;
}

.container {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
@media screen and (max-width: 599px) {
  .container {
    overflow: hidden;
  }
}

.container_inner {
  box-sizing: border-box;
  max-width: 1204px;
  width: 78.590078329%;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .container_inner {
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.topSectionCompany_container {
  position: relative;
  background-image: url("../img/top/company_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.topSectionCompany_container:after {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0.5);
}

.topSectionCompany_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  padding-top: 30px;
  padding-bottom: 40px;
}
@media screen and (max-width: 750px) {
  .topSectionCompany_inner {
    display: block;
  }
}

.topSectionRecruitmenty_container {
  position: relative;
  overflow: hidden;
  background-color: #00A0E9;
}
.topSectionRecruitmenty_container:before, .topSectionRecruitmenty_container:after {
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  width: 160px;
  height: 500px;
  background: #0056A2;
  transform: rotate(45deg);
}
.topSectionRecruitmenty_container:before {
  top: -170px;
  left: 0;
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_container:before {
    top: -320px;
  }
}
.topSectionRecruitmenty_container:after {
  bottom: -220px;
  right: 0;
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_container:after {
    bottom: -320px;
  }
}

.topSectionRecruitmenty_inner {
  padding-top: 36px;
  padding-bottom: 36px;
}

.recruit_dropDownMenu {
  display: none;
  background-color: rgba(255, 255, 255, 0.7);
}

.recruit_dropDownMenuTrigger:hover .recruit_dropDownMenu {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
}

.recruit_dropDownItem {
  border-top: 1px dotted #4a4a4a;
}
.recruit_dropDownItem a {
  letter-spacing: 0;
  line-height: 1.1;
  font-size: 18px;
}
.recruit_dropDownItem a:link, .recruit_dropDownItem a:visited, .recruit_dropDownItem a:hover, .recruit_dropDownItem a:active, .recruit_dropDownItem a:focus {
  color: #595757;
  text-decoration: none;
}
.recruit_dropDownItem:first-child {
  border-top: none;
}

.footer {
  width: 100%;
}

.footer_inner {
  width: 100%;
  max-width: 100%;
  padding: 20px 0 36px;
}
@media screen and (max-width: 960px) {
  .footer_inner {
    padding: 20px 0 20px;
  }
}

.fnav {
  width: 50%;
  margin: 0 auto 64px;
}
@media screen and (max-width: 960px) {
  .fnav {
    display: none;
  }
}

.fnav_menuList {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.fnav_menuItem {
  position: relative;
  margin-right: 2em;
  margin-left: 0.5em;
}
.fnav_menuItem:first-child {
  margin-left: 0;
}
.fnav_menuItem:last-child {
  margin-right: 0;
}
.fnav_menuItem:last-child:after {
  display: none;
}
.fnav_menuItem:after {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.5em;
  top: 40%;
  right: -1.5em;
  transform: translate(-50%, -50%) rotate(20deg);
  background-color: #000000;
}
.fnav_menuItem a {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.06em;
  transition: 0.3s;
}
.fnav_menuItem a:link, .fnav_menuItem a:visited, .fnav_menuItem a:hover, .fnav_menuItem a:active, .fnav_menuItem a:focus {
  color: #000;
  text-decoration: none;
}
.fnav_menuItem a:active, .fnav_menuItem a:link, .fnav_menuItem a:visited, .fnav_menuItem a:focus {
  color: #000000;
}
.fnav_menuItem a:hover {
  text-decoration: none;
  opacity: 0.7;
  transition: 0.3s;
}

.footer_logo {
  max-width: 23%;
  margin: 0 auto;
}
@media screen and (max-width: 599px) {
  .footer_logo {
    max-width: 46%;
  }
}
.footer_logo a {
  transition: all 0.3s ease;
}
.footer_logo a:hover {
  transition: all 0.3s ease;
  opacity: 0.7;
}

.header {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .header {
    height: 60px;
    z-index: 7999;
  }
}

.header_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  padding-left: 4.1775456919%;
}

.header_logo {
  transition: all 0.3s ease;
  background-image: url("../img/top/header_logo.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16%;
  max-width: 240px;
}
.header_logo:hover {
  opacity: 0.7;
}
@media screen and (max-width: 599px) {
  .header_logo {
    width: 26%;
  }
}
@media screen and (max-width: 414px) {
  .header_logo {
    width: 30%;
  }
}
.header_logo a {
  display: block;
  width: 100%;
  padding-top: 77px;
}
@media screen and (max-width: 960px) {
  .header_logo a {
    padding-top: 54px;
  }
}

.gnav {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
  width: 84%;
}
@media screen and (max-width: 960px) {
  .gnav {
    width: auto;
  }
}

.gnav_list {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .gnav_list {
    display: block;
    width: auto;
    padding: 20px;
  }
}

.gnav_listItem {
  margin-right: 50px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .gnav_listItem {
    margin-right: 36px;
  }
}
@media screen and (max-width: 960px) {
  .gnav_listItem {
    position: relative;
    margin-right: 0;
    text-align: left;
  }
}
.gnav_listItem:after {
  position: absolute;
  display: none;
  content: "";
  width: 5px;
  height: 5px;
  border-right: 2px solid #595757;
  border-bottom: 2px solid #595757;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50%;
  right: 0;
}
@media screen and (max-width: 960px) {
  .gnav_listItem:after {
    display: block;
  }
}
.gnav_listItem:first-child a {
  padding: 23px 0 36px;
}
@media screen and (max-width: 960px) {
  .gnav_listItem:first-child a {
    padding: 16px 0;
  }
}
.gnav_listItem:last-child {
  margin-right: 0;
}
.gnav_listItem a {
  transition: all 0.3s ease;
  display: block;
  padding: 18px 0;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  color: #595757;
  line-height: 1.1;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 960px) {
  .gnav_listItem a {
    padding: 16px 0;
  }
}
.gnav_listItem a:active, .gnav_listItem a:link, .gnav_listItem a:visited, .gnav_listItem a:focus {
  color: #595757;
}
.gnav_listItem a:hover {
  transition: all 0.3s ease;
  opacity: 0.7;
}
.gnav_listItem span {
  margin-top: 2px;
}
@media screen and (max-width: 960px) {
  .gnav_listItem span {
    margin-top: auto;
  }
}
.gnav_listItem.is_current {
  text-decoration: underline;
  text-decoration-color: #595757;
  text-decoration-thickness: 1px;
  text-underline-offset: 12px;
}

.top_headingUnit {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
}
@media screen and (max-width: 599px) {
  .top_headingUnit {
    flex-flow: row nowrap;
    justify-content: start;
    margin-bottom: 8px;
  }
}

.topSectionNews_headingUnit {
  margin-bottom: 1.4em;
}

.top_panel01_headingUnit {
  justify-content: start;
  margin-bottom: 20px;
}
.top_panel01_headingUnit.isEnlarge_left {
  position: relative;
  z-index: 1;
  width: 115%;
  margin-left: -15%;
}

.topSectionCompany_headingUnit {
  justify-content: start;
  margin-bottom: 56px;
}
@media screen and (max-width: 599px) {
  .topSectionCompany_headingUnit {
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 599px) {
  .topSectionCompany_headingUnit {
    margin-bottom: 35px;
  }
}

.topSectionRecruitmenty_headingUnit {
  justify-content: start;
  margin-bottom: 1.5em;
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_headingUnit {
    margin-bottom: 1em;
  }
}

.top_heading01 {
  font-family: Avenir;
  font-size: 28px;
  font-weight: 500;
  color: #595757;
  line-height: 1.1;
  letter-spacing: 0.06em;
  margin-right: 42px;
}
@media screen and (max-width: 960px) {
  .top_heading01 {
    font-size: 24px;
    margin-right: 22px;
  }
}
@media screen and (max-width: 599px) {
  .top_heading01 {
    margin-right: 18px;
    white-space: nowrap;
  }
}
@media screen and (max-width: 414px) {
  .top_heading01 {
    font-size: 20px;
  }
}

.top_heading01_lead {
  position: relative;
  display: inline-block;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 599px) {
  .top_heading01_lead {
    white-space: nowrap;
    font-size: 14px;
  }
}
.top_heading01_lead:before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1em;
  top: 50%;
  left: -0.7em;
  transform: translate(-50%, -50%) rotate(25deg);
  background-color: #000000;
}
@media screen and (max-width: 599px) {
  .top_heading01_lead:before {
    left: -0.6em;
  }
}

.topSectionRecruitmenty_heading01 {
  color: #fff;
  font-size: 42px;
}

.topSectionRecruitmenty_heading01_lead {
  color: #fff;
}
.topSectionRecruitmenty_heading01_lead:before {
  background-color: #fff;
}

.top_heading02 {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.1;
}
@media screen and (max-width: 599px) {
  .top_heading02 {
    font-size: 16px;
  }
}
.top_heading02 img {
  max-width: 19em;
  margin-right: 12px;
}
@media screen and (max-width: 599px) {
  .top_heading02 img {
    max-width: 15em;
  }
}
@media screen and (max-width: 414px) {
  .top_heading02 img {
    max-width: 11.6em;
  }
}

.topSectionCompany_heading02 {
  margin-bottom: 34px;
}
@media screen and (max-width: 599px) {
  .topSectionCompany_heading02 {
    margin-bottom: 21px;
  }
}

.headline01Group {
  background-color: rgba(255, 255, 255, 0.6);
  border-top: 2px solid #0056A2;
  border-bottom: 2px solid #0056A2;
  max-width: 100%;
  padding: 60px;
}
@media screen and (max-width: 960px) {
  .headline01Group {
    padding: 32px;
  }
}
@media screen and (max-width: 414px) {
  .headline01Group {
    padding: 24px 16px;
  }
}

.headline01Item {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 34px;
}
@media screen and (max-width: 599px) {
  .headline01Item {
    margin-bottom: 24px;
  }
}
.headline01Item:last-child {
  margin-bottom: 0;
}

.headline01Item_date,
.headline01Item_contents {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .headline01Item_date,
.headline01Item_contents {
    font-size: 14px;
  }
}

.headline01Item_date {
  position: relative;
  margin-right: 1em;
}
.headline01Item_date:after {
  position: absolute;
  display: block;
  content: "/";
  top: 0.8em;
  right: -1em;
  transform: translate(-50%, -50%);
}

.headline01Item_contents p {
  font-size: 18px;
}
@media screen and (max-width: 599px) {
  .headline01Item_contents p {
    font-size: 14px;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 56.51vw;
}
@media screen and (max-width: 599px) {
  .hero {
    height: 216.6vw;
  }
}
.top_heroSlider {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

#js_heroSlider_pc {
  display: block !important;
}
@media screen and (max-width: 599px) {
  #js_heroSlider_pc {
    display: none !important;
  }
}

#js_heroSlider_sp {
  display: none !important;
}
@media screen and (max-width: 599px) {
  #js_heroSlider_sp {
    display: block !important;
  }
}

.top_heroImg {
  width: 100%;
  max-width: 100%;
  max-height: 56.51vw;
  overflow: hidden;
}
@media screen and (max-width: 599px) {
  .top_heroImg {
    max-height: 216.6vw;
  }
}
.top_heroImg img {
  height: 56.51vw;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .top_heroImg img {
    height: 216.6vw;
  }
}

.topHero_headingGroup {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45.3277545328vw;
}
@media screen and (max-width: 599px) {
  .topHero_headingGroup {
    top: 50%;
    width: 88.4615384615%;
  }
}

.hero_heading {
  margin-bottom: 1.5vw;
  text-align: end;
  white-space: nowrap;
  letter-spacing: 0.085em;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1.8vw;
  font-weight: bold;
  color: #0056A2;
  text-shadow: 3px 3px 3px rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 599px) {
  .hero_heading {
    font-size: 3.3vw;
    margin-bottom: 3vw;
  }
}

.hero_logo {
  width: 100%;
  margin-bottom: 40px;
}
.hero_logo img {
  width: 100%;
  max-width: 100%;
  filter: drop-shadow(3px 3px 3px rgba(255, 255, 255, 0.7));
}

.modal {
  z-index: 31000;
}

.modal-backdrop.show {
  z-index: 30999;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.7);
}

.modal-dialog {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

.modal_content {
  padding: 30px;
}

.offcanvasTrigger {
  z-index: 7999;
  position: fixed;
}

.js_isOffcanvasActive {
  max-width: 100%;
}

.offcanvasMenu {
  display: none;
}
@media screen and (max-width: 960px) {
  .offcanvasMenu {
    width: calc(100% - 60px);
    background: #fff;
    overflow-x: hidden;
    transition: all 0.3s ease;
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .offcanvasMenu {
    z-index: 7998;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(-100%, 0);
    padding: 60px 0;
  }
}

.js_isOffcanvasActive .offcanvasMenu {
  transform: translate(0%, 0);
}

@media screen and (max-width: 960px) {
  .offcanvasBg {
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.4s ease;
  }
}

@media screen and (max-width: 960px) {
  .offcanvasBg {
    z-index: 7997;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    transform: translate(-100%, 0);
  }
}

.js_isOffcanvasActive .offcanvasBg {
  transform: translate(0%, 0);
}

.js_isOffcanvasActive .offcanvasTrigger_labelOpen {
  display: none;
}

.offcanvasTrigger_labelClose {
  display: none;
}

.js_isOffcanvasActive .offcanvasTrigger_labelClose {
  display: inline;
}

.offcanvasGroup {
  display: none;
  position: relative;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #00A0E9;
}
@media screen and (max-width: 960px) {
  .offcanvasGroup {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .offcanvasTrigger {
    display: none;
  }
}

.offcanvasMenu_lead {
  position: relative;
  text-decoration: none;
  font-size: 10px;
}
@media screen and (max-width: 960px) {
  .offcanvasMenu_lead {
    font-size: 12px;
    margin-left: 1em;
  }
}
.offcanvasMenu_lead:before {
  position: absolute;
  left: -0.5em;
  display: none;
  content: "";
  height: 1em;
  width: 1px;
  background-color: #595757;
  transform: rotate(15deg);
}
@media screen and (max-width: 960px) {
  .offcanvasMenu_lead:before {
    display: block;
  }
}

.offcanvasFooter {
  display: none;
}
@media screen and (max-width: 960px) {
  .offcanvasFooter {
    display: block;
    width: 100%;
  }
}

.offcanvasFooter_menu {
  display: none;
}
@media screen and (max-width: 960px) {
  .offcanvasFooter_menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
  }
}

.offcanvasFooter_menu_item {
  display: none;
}
@media screen and (max-width: 960px) {
  .offcanvasFooter_menu_item {
    position: relative;
    display: block;
    width: calc((100% - 1px) / 2);
    border-top: 1px solid #595757;
    border-bottom: 1px solid #595757;
  }
}
@media screen and (max-width: 960px) {
  .offcanvasFooter_menu_item:first-child {
    border-right: 1px solid #595757;
  }
}
.offcanvasFooter_menu_item:after {
  position: absolute;
  top: 45%;
  right: 1.4em;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  border-right: 2px solid #595757;
  border-bottom: 2px solid #595757;
  transform: rotate(-45deg) translate(-50%, -50%);
}
@media screen and (max-width: 960px) {
  .offcanvasFooter_menu_item a {
    transition: all 0.3s ease;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
    font-size: 15px;
    line-height: 1.1;
    padding: 21px 21px 20px;
  }
  .offcanvasFooter_menu_item a:link, .offcanvasFooter_menu_item a:visited, .offcanvasFooter_menu_item a:hover, .offcanvasFooter_menu_item a:active, .offcanvasFooter_menu_item a:focus {
    color: #3b3b3b;
    text-decoration: none;
  }
  .offcanvasFooter_menu_item a:hover {
    opacity: 0.7;
  }
}

.offcanvasFooter_btn_group {
  padding: 18px;
}

.offcanvasFooterTel_btn {
  background-color: #FFF100;
  margin-bottom: 8px;
  padding: 3px 0 2px;
}

.offcanvasTriggerUnit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 7999;
  display: none;
}
@media screen and (max-width: 1024px) {
  .offcanvasTriggerUnit {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.offcanvasTrigger,
.offcanvasTrigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.offcanvasTrigger {
  position: relative;
  width: 31px;
  height: 23px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
}

.offcanvasTrigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.offcanvasTrigger span:nth-of-type(1) {
  top: 0;
}

.offcanvasTrigger span:nth-of-type(2) {
  top: 10px;
}
@media screen and (max-width: 414px) {
  .offcanvasTrigger span:nth-of-type(2) {
    top: 45%;
  }
}

.offcanvasTrigger span:nth-of-type(3) {
  bottom: 0;
}

.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(1) {
  transform: translateY(11px) rotate(-45deg);
  background-color: #fff;
}

.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  animation: active-menu05-bar02 0.8s forwards;
}

@keyframes active-menu05-bar02 {
  100% {
    height: 0;
  }
}
.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
  background-color: #fff;
}

.offcanvasTrigger_label {
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  color: #fff;
}

.js_isOffcanvasActive .offcanvasTrigger_label {
  color: #fff;
}

.offcanvasRecruit_menu {
  display: none;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_menu {
    display: block;
    margin-top: 20px;
  }
}

.offcanvasRecruit_item {
  margin-right: 50px;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_item {
    position: relative;
    margin-right: 0;
  }
}
.offcanvasRecruit_item:after {
  position: absolute;
  display: none;
  content: "";
  width: 5px;
  height: 5px;
  border-right: 2px solid #595757;
  border-bottom: 2px solid #595757;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50%;
  right: 0;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_item:after {
    display: block;
  }
}
.offcanvasRecruit_item:last-child {
  margin-right: 0;
}
.offcanvasRecruit_item a {
  transition: all 0.3s ease;
  display: block;
  padding: 30px 0;
  font-family: Avenir;
  font-size: 28px;
  font-weight: 500;
  color: #595757;
  line-height: 1.1;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_item a {
    font-size: 20px;
    padding: 16px 0;
  }
}
.offcanvasRecruit_item a:active, .offcanvasRecruit_item a:link, .offcanvasRecruit_item a:visited, .offcanvasRecruit_item a:focus {
  color: #595757;
}
.offcanvasRecruit_item a:hover {
  transition: all 0.3s ease;
  opacity: 0.7;
}
.offcanvasRecruit_item.is_current {
  text-decoration: underline;
  text-decoration-color: #595757;
  text-decoration-thickness: 1px;
  text-underline-offset: 12px;
}

.offcanvasRecruit_itemPrimal {
  background-color: #00A0E9;
  margin-bottom: 10px;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_itemPrimal {
    width: 106%;
    margin-left: -3%;
  }
}
@media screen and (max-width: 599px) {
  .offcanvasRecruit_itemPrimal {
    width: 109%;
    margin-left: -5%;
  }
}
@media screen and (max-width: 414px) {
  .offcanvasRecruit_itemPrimal {
    width: 112%;
    margin-left: -6%;
  }
}
.offcanvasRecruit_itemPrimal:after {
  right: 1.5em;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
@media screen and (max-width: 960px) {
  .offcanvasRecruit_itemPrimal a {
    padding: 16px 18px;
    color: #fff;
  }
  .offcanvasRecruit_itemPrimal a:active, .offcanvasRecruit_itemPrimal a:link, .offcanvasRecruit_itemPrimal a:visited, .offcanvasRecruit_itemPrimal a:focus, .offcanvasRecruit_itemPrimal a:hover {
    color: #fff;
  }
}
.offcanvasRecruit_itemPrimal a .offcanvasMenu_lead:before {
  background-color: #fff;
}

.pagetop {
  position: fixed;
  right: 15px;
  bottom: 20px;
  transform: translate(0%, 200%);
}

.pagetop.js_isFixed {
  transform: translate(0%, -100%);
  bottom: 0;
}

.pagetop.js_isHold {
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: auto;
  transform: translate(0%, -50%);
}

.pagetop {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #0056A2;
  border-radius: 200px;
  transition: all 0.3s ease;
}
.pagetop:hover {
  background: #2c0851;
  cursor: pointer;
}

.pagetop svg {
  width: 50%;
}

.pagetop_icon_svg {
  fill: #fff;
}

.panel01 {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  background-color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 599px) {
  .panel01 {
    display: block;
    background-color: transparent;
  }
}

.panel01_img {
  transition: all 0.3s ease;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  position: relative;
  left: -3.3%;
  width: 33.3333333333%;
  transform: skew(-6deg);
  overflow: hidden;
  box-shadow: 2px 3px 5px 1px rgba(35, 24, 21, 0.7);
}
@media screen and (max-width: 960px) {
  .panel01_img {
    left: 0;
  }
}
@media screen and (max-width: 599px) {
  .panel01_img {
    width: 100%;
    margin-left: -16%;
    margin-right: 0;
    min-height: 224px;
    transform: skew(-10deg);
  }
}
@media screen and (max-width: 414px) {
  .panel01_img {
    margin-left: -10%;
  }
}
.panel01_img a {
  display: block;
  position: relative;
  width: 100%;
}
.panel01_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: skew(6deg) translate(-50%, -50%);
  width: 150%;
  max-width: 150%;
  height: 120%;
  filter: grayscale(100%);
  object-fit: cover;
}
.panel01_img:hover img, .js_isHover .panel01_img img {
  transition: all 0.3s ease;
  filter: grayscale(0%);
}
@media screen and (max-width: 599px) {
  .panel01:nth-of-type(even) .panel01_img {
    margin-left: 25%;
  }
}
@media screen and (max-width: 414px) {
  .panel01:nth-of-type(even) .panel01_img {
    margin-left: 15%;
  }
}

.panel01ContentsGroup {
  box-sizing: border-box;
  width: 66.6666666667%;
  padding: 20px 40px;
  margin-left: auto;
}
@media screen and (max-width: 599px) {
  .panel01ContentsGroup {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 24px 0;
    padding: 0;
  }
}

.topSectionContents_panel01 {
  margin-bottom: 80px;
}
@media screen and (max-width: 960px) {
  .topSectionContents_panel01 {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 750px) {
  .topSectionContents_panel01 {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 414px) {
  .topSectionContents_panel01 {
    margin-bottom: 60px;
  }
}
.topSectionContents_panel01:last-child {
  margin-bottom: 0;
}

.panel01_foreignWorker_img {
  left: -5%;
  transform: skew(0);
}
@media screen and (max-width: 960px) {
  .panel01_foreignWorker_img {
    left: -3%;
  }
}
.panel01_foreignWorker_img img {
  transform: skew(0) translate(-50%, -50%);
}

.sg_container_wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px 100px 0 100px;
}
@media screen and (max-width: 960px) {
  .sg_container_wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sg_container {
  width: calc(100% - 350px);
}
.sg_container_inner {
  margin: 0 auto;
}
.sg_container_inner > *:first-child {
  margin-top: 0 !important;
}
@media screen and (max-width: 960px) {
  .sg_container {
    width: 100%;
  }
}

.sg_side_container {
  width: 280px;
  min-height: 500px;
  border-right: 1px solid #ccc;
}
@media screen and (max-width: 960px) {
  .sg_side_container {
    display: none;
  }
}

.sg_nav_body {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  margin: 0 100px 0 auto;
  padding: 0;
}
.sg_nav_body li {
  display: block;
  width: 100%;
  padding: 5px 10px;
}
.sg_nav_body li a {
  display: block;
  padding: 0.5em 0;
}
.sg_nav_body li a:active, .sg_nav_body li a:link, .sg_nav_body li a:visited, .sg_nav_body li a:focus, .sg_nav_body li a:hover {
  color: #333;
  text-decoration: none;
}

.sg_header {
  background: #eee;
  height: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
}
.sg_header_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  width: 100%;
  max-width: 100%;
}
@media screen and (max-width: 960px) {
  .sg_header_inner {
    padding: 0 10px;
  }
}

.sg_nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  margin-left: auto;
  padding: 0;
}
.sg_nav li a {
  display: block;
  padding: 0.25em 1em;
}
.sg_nav li a:active, .sg_nav li a:link, .sg_nav li a:visited, .sg_nav li a:focus, .sg_nav li a:hover {
  color: #3b3b3b;
  text-decoration: none;
  font-weight: bold;
}
@media screen and (max-width: 960px) {
  .sg_nav {
    margin-left: 0;
  }
}

.sg_mv {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: #ccc;
}

.sg_mv_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_mv_title {
  width: 100%;
  margin: 0 auto 0.5em;
  font-size: 1.5em;
  text-align: center;
  line-height: 1.4;
}

.sg_mv_lead {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}

.sg_footer {
  position: relative;
  background: #eee;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_copy {
  font-size: 11px;
  line-height: 1;
  padding: 1em;
}

.sg_heading01 {
  font-size: 2.5em;
  margin: 2em 0 2em;
}

.sg_heading02 {
  font-size: 2.2em;
  margin: 2em 0 1em;
  padding-bottom: 0.5em;
  border-bottom: solid 5px #ccc;
}

.sg_heading04 {
  font-size: 1.5em;
  margin: 2em 0 1em;
}

.sg_btn01 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: inline-block;
  border: solid 1px #0028b7;
  padding: 0.5em 5em 0.6em;
  border-radius: 5em;
  font-size: 1em;
  color: #0028b7;
  background-color: #ffffff;
}

.sg_code_inline {
  display: inline-block;
  border: solid 1px #ec007a;
  background: #ffecf8;
  color: #ec007a;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

.sg_path_inline {
  display: inline-block;
  border: solid 1px #ccc;
  background: #f6f6f6;
  color: #333;
  border-radius: 5px;
  margin-left: 0.5em;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

.sg_section_unit {
  margin-bottom: 10em;
}

.sg_demo_unit {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 20px;
  margin-bottom: 2em;
}
.sg_demo_unit:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  content: "DEMO";
  background: #fff;
  padding: 0.3em 0.5em;
  font-size: 10px;
  line-height: 1.1;
  border-left: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-radius: 0 10px 0 0;
}
.sg_textUnit {
  margin-bottom: 2em;
}
.sg_textUnit > p {
  margin-bottom: 1em;
  line-height: 1.6em;
}
.sg_textUnit > p:last-child {
  margin-bottom: 0;
}

.sg_editContainer ul {
  margin-left: 2em;
}
.sg_editContainer li {
  margin-bottom: 1.5em;
}
.sg_editContainer table tr {
  border: solid 1px #ddd;
}
.sg_editContainer table th, .sg_editContainer table td {
  padding: 1em;
}
.sg_editContainer table th {
  background: #f3f3f3;
  text-align: left;
}
.scrollDownUnit {
  position: relative;
  width: 13.315926893vw;
  height: 13.315926893vw;
}
@media screen and (max-width: 599px) {
  .scrollDownUnit {
    width: 25.641025641vw;
    height: 25.641025641vw;
  }
}

.scrollDownUnit_circle {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 13.315926893vw;
  height: 13.315926893vw;
  border-radius: 50%;
  border: solid 1px #0056A2;
}
@media screen and (max-width: 599px) {
  .scrollDownUnit_circle {
    width: 25.641025641vw;
    height: 25.641025641vw;
  }
}

.scrollDownUnit_item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0056A2;
  animation: circle-move-anim 4s linear infinite;
}

.scrollDownUnit_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  font-family: Avenir;
  font-size: 1.5vw;
  color: #0056A2;
  letter-spacing: 0.26em;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.7);
  text-align: center;
}
@media screen and (max-width: 599px) {
  .scrollDownUnit_text {
    font-size: 3vw;
  }
}

@keyframes circle-move-anim {
  0% {
    transform: rotate(0deg) translateX(calc(-6.6579634465vw)) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(calc(-6.6579634465vw)) rotate(-360deg);
  }
}
@media screen and (max-width: 599px) {
  @keyframes circle-move-anim {
    0% {
      transform: rotate(0deg) translateX(calc(-12.8205128205vw)) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(calc(-12.8205128205vw)) rotate(-360deg);
    }
  }
}
.textUnit p {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 18px;
  line-height: 1.8em;
}
@media screen and (max-width: 599px) {
  .textUnit p {
    font-size: 14px;
    line-height: 1.7;
  }
}

.top_panel01_textUnit {
  margin-bottom: 20px;
}

@media screen and (max-width: 750px) {
  .topSectionCompany_textUnit {
    margin-bottom: 32px;
  }
}

.topSectionRecruitmenty_textUnit p {
  color: #fff;
}

.topStripeContainer {
  background-repeat: repeat;
  background-image: url("../img/top/bg_pc.webp");
  background-position: center top;
  background-size: 100%;
  padding: 80px 0;
}
@media screen and (max-width: 960px) {
  .topStripeContainer {
    padding: 70px 0;
  }
}
@media screen and (max-width: 750px) {
  .topStripeContainer {
    padding: 60px 0;
    background-image: url("../img/top/bg_sp.webp");
  }
}

.topSectionNews_container {
  margin-bottom: 80px;
}
@media screen and (max-width: 960px) {
  .topSectionNews_container {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 750px) {
  .topSectionNews_container {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 414px) {
  .topSectionNews_container {
    margin-bottom: 60px;
  }
}

.topSectionCompany_contentsUnit {
  position: relative;
  z-index: 1;
  width: calc(100% - 404px);
  margin-right: 120px;
}
@media screen and (max-width: 960px) {
  .topSectionCompany_contentsUnit {
    margin-right: 60px;
    width: calc(100% - 344px);
  }
}
@media screen and (max-width: 750px) {
  .topSectionCompany_contentsUnit {
    width: 100%;
    margin-right: 0;
  }
}

.topSectionCompany_btnGroup {
  position: relative;
  z-index: 1;
  align-items: end;
  justify-content: end;
  min-width: 244px;
  max-width: 244px;
  margin-right: 40px;
  margin-left: auto;
}
@media screen and (max-width: 750px) {
  .topSectionCompany_btnGroup {
    max-width: none;
    min-width: auto;
    width: 100%;
    justify-content: center;
  }
}

.topSectionRecruitmenty_contentsGroup {
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 36px;
}
@media screen and (max-width: 750px) {
  .topSectionRecruitmenty_contentsGroup {
    display: block;
  }
}

.topSectionRecruitmenty_primalyUnit {
  width: calc(100% - 356px);
  margin-right: 36px;
}
@media screen and (max-width: 1024px) {
  .topSectionRecruitmenty_primalyUnit {
    width: calc(100% - 269px);
    margin-right: 24px;
  }
}
@media screen and (max-width: 750px) {
  .topSectionRecruitmenty_primalyUnit {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.topSectionRecruitmenty_secondalyUnit {
  min-width: 320px;
  max-width: 320px;
}
@media screen and (max-width: 1024px) {
  .topSectionRecruitmenty_secondalyUnit {
    max-width: 245px;
    min-width: 245px;
  }
}
@media screen and (max-width: 750px) {
  .topSectionRecruitmenty_secondalyUnit {
    max-width: 260px;
    min-width: 260px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 599px) {
  .topSectionRecruitmenty_secondalyUnit {
    max-width: 236px;
    min-width: 236px;
  }
}

.mentenance_img {
  width: 100%;
  margin: 0 auto;
}
.mentenance_img img {
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1980px) {
  .hidden_xlg {
    display: none !important;
  }
}

@media screen and (max-width: 1366px) {
  .hidden_lg {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .hidden_mlg {
    display: none !important;
  }
}

@media screen and (max-width: 960px) {
  .hidden_slg {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .hidden_md {
    display: none !important;
  }
}

@media screen and (max-width: 667px) {
  .hidden_6ls {
    display: none !important;
  }
}

@media screen and (max-width: 568px) {
  .hidden_5ls {
    display: none !important;
  }
}

@media screen and (max-width: 414px) {
  .hidden_sm {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .hidden_msm {
    display: none !important;
  }
}

@media screen and (max-width: 320px) {
  .hidden_xs {
    display: none !important;
  }
}

.visible_xlg {
  display: none !important;
}
@media screen and (max-width: 1980px) {
  .visible_xlg {
    display: block !important;
  }
}

.visible_lg {
  display: none !important;
}
@media screen and (max-width: 1366px) {
  .visible_lg {
    display: block !important;
  }
}

.visible_mlg {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .visible_mlg {
    display: block !important;
  }
}

.visible_slg {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .visible_slg {
    display: block !important;
  }
}

.visible_md {
  display: none !important;
}
@media screen and (max-width: 599px) {
  .visible_md {
    display: block !important;
  }
}

.visible_6ls {
  display: none !important;
}
@media screen and (max-width: 667px) {
  .visible_6ls {
    display: block !important;
  }
}

.visible_5ls {
  display: none !important;
}
@media screen and (max-width: 568px) {
  .visible_5ls {
    display: block !important;
  }
}

.visible_sm {
  display: none !important;
}
@media screen and (max-width: 414px) {
  .visible_sm {
    display: block !important;
  }
}

.visible_msm {
  display: none !important;
}
@media screen and (max-width: 375px) {
  .visible_msm {
    display: block !important;
  }
}

.visible_xs {
  display: none !important;
}
@media screen and (max-width: 320px) {
  .visible_xs {
    display: block !important;
  }
}

.visible_xlg_inline {
  display: none !important;
}
@media screen and (max-width: 1980px) {
  .visible_xlg_inline {
    display: inline-block !important;
  }
}

.visible_lg_inline {
  display: none !important;
}
@media screen and (max-width: 1366px) {
  .visible_lg_inline {
    display: inline-block !important;
  }
}

.visible_mlg_inline {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .visible_mlg_inline {
    display: inline-block !important;
  }
}

.visible_slg_inline {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .visible_slg_inline {
    display: inline-block !important;
  }
}

.visible_md_inline {
  display: none !important;
}
@media screen and (max-width: 599px) {
  .visible_md_inline {
    display: inline-block !important;
  }
}

.visible_6ls_inline {
  display: none !important;
}
@media screen and (max-width: 667px) {
  .visible_6ls_inline {
    display: inline-block !important;
  }
}

.visible_5ls_inline {
  display: none !important;
}
@media screen and (max-width: 568px) {
  .visible_5ls_inline {
    display: inline-block !important;
  }
}

.visible_sm_inline {
  display: none !important;
}
@media screen and (max-width: 414px) {
  .visible_sm_inline {
    display: inline-block !important;
  }
}

.visible_msm_inline {
  display: none !important;
}
@media screen and (max-width: 375px) {
  .visible_msm_inline {
    display: inline-block !important;
  }
}

.visible_xs_inline {
  display: none !important;
}
@media screen and (max-width: 320px) {
  .visible_xs_inline {
    display: inline-block !important;
  }
}

.dis_block {
  display: block;
}

.dis_inlineBlock {
  display: inline-block;
}

.dis_flex {
  display: flex;
}

.dis_hidden {
  display: none;
}

.mt_0 {
  margin-top: 0px !important;
}

@media screen and (max-width: 1980px) {
  .mt_0_xlg {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_0_lg {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_0_mlg {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_0_slg {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_0_md {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_0_6ls {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_0_5ls {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_0_sm {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_0_msm {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_0_xs {
    margin-top: 0px !important;
  }
}
.mb_0 {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1980px) {
  .mb_0_xlg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_0_lg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_0_mlg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_0_slg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_0_md {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_0_6ls {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_0_5ls {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_0_sm {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_0_msm {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_0_xs {
    margin-bottom: 0px !important;
  }
}
.mt_1 {
  margin-top: 1px !important;
}

@media screen and (max-width: 1980px) {
  .mt_1_xlg {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_1_lg {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_1_mlg {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_1_slg {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_1_md {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_1_6ls {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_1_5ls {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_1_sm {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_1_msm {
    margin-top: 1px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_1_xs {
    margin-top: 1px !important;
  }
}
.mb_1 {
  margin-bottom: 1px !important;
}

@media screen and (max-width: 1980px) {
  .mb_1_xlg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_1_lg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_1_mlg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_1_slg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_1_md {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_1_6ls {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_1_5ls {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_1_sm {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_1_msm {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_1_xs {
    margin-bottom: 1px !important;
  }
}
.mt_2 {
  margin-top: 2px !important;
}

@media screen and (max-width: 1980px) {
  .mt_2_xlg {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_2_lg {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_2_mlg {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_2_slg {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_2_md {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_2_6ls {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_2_5ls {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_2_sm {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_2_msm {
    margin-top: 2px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_2_xs {
    margin-top: 2px !important;
  }
}
.mb_2 {
  margin-bottom: 2px !important;
}

@media screen and (max-width: 1980px) {
  .mb_2_xlg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_2_lg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_2_mlg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_2_slg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_2_md {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_2_6ls {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_2_5ls {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_2_sm {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_2_msm {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_2_xs {
    margin-bottom: 2px !important;
  }
}
.mt_3 {
  margin-top: 3px !important;
}

@media screen and (max-width: 1980px) {
  .mt_3_xlg {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_3_lg {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_3_mlg {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_3_slg {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_3_md {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_3_6ls {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_3_5ls {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_3_sm {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_3_msm {
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_3_xs {
    margin-top: 3px !important;
  }
}
.mb_3 {
  margin-bottom: 3px !important;
}

@media screen and (max-width: 1980px) {
  .mb_3_xlg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_3_lg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_3_mlg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_3_slg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_3_md {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_3_6ls {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_3_5ls {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_3_sm {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_3_msm {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_3_xs {
    margin-bottom: 3px !important;
  }
}
.mt_4 {
  margin-top: 4px !important;
}

@media screen and (max-width: 1980px) {
  .mt_4_xlg {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_4_lg {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_4_mlg {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_4_slg {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_4_md {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_4_6ls {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_4_5ls {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_4_sm {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_4_msm {
    margin-top: 4px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_4_xs {
    margin-top: 4px !important;
  }
}
.mb_4 {
  margin-bottom: 4px !important;
}

@media screen and (max-width: 1980px) {
  .mb_4_xlg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_4_lg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_4_mlg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_4_slg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_4_md {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_4_6ls {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_4_5ls {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_4_sm {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_4_msm {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_4_xs {
    margin-bottom: 4px !important;
  }
}
.mt_5 {
  margin-top: 5px !important;
}

@media screen and (max-width: 1980px) {
  .mt_5_xlg {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_5_lg {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_5_mlg {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_5_slg {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_5_md {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_5_6ls {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_5_5ls {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_5_sm {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_5_msm {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_5_xs {
    margin-top: 5px !important;
  }
}
.mb_5 {
  margin-bottom: 5px !important;
}

@media screen and (max-width: 1980px) {
  .mb_5_xlg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_5_lg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_5_mlg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_5_slg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_5_md {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_5_6ls {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_5_5ls {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_5_sm {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_5_msm {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_5_xs {
    margin-bottom: 5px !important;
  }
}
.mt_6 {
  margin-top: 6px !important;
}

@media screen and (max-width: 1980px) {
  .mt_6_xlg {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_6_lg {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_6_mlg {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_6_slg {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_6_md {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_6_6ls {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_6_5ls {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_6_sm {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_6_msm {
    margin-top: 6px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_6_xs {
    margin-top: 6px !important;
  }
}
.mb_6 {
  margin-bottom: 6px !important;
}

@media screen and (max-width: 1980px) {
  .mb_6_xlg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_6_lg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_6_mlg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_6_slg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_6_md {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_6_6ls {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_6_5ls {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_6_sm {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_6_msm {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_6_xs {
    margin-bottom: 6px !important;
  }
}
.mt_7 {
  margin-top: 7px !important;
}

@media screen and (max-width: 1980px) {
  .mt_7_xlg {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_7_lg {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_7_mlg {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_7_slg {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_7_md {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_7_6ls {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_7_5ls {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_7_sm {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_7_msm {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_7_xs {
    margin-top: 7px !important;
  }
}
.mb_7 {
  margin-bottom: 7px !important;
}

@media screen and (max-width: 1980px) {
  .mb_7_xlg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_7_lg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_7_mlg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_7_slg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_7_md {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_7_6ls {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_7_5ls {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_7_sm {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_7_msm {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_7_xs {
    margin-bottom: 7px !important;
  }
}
.mt_8 {
  margin-top: 8px !important;
}

@media screen and (max-width: 1980px) {
  .mt_8_xlg {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_8_lg {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_8_mlg {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_8_slg {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_8_md {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_8_6ls {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_8_5ls {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_8_sm {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_8_msm {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_8_xs {
    margin-top: 8px !important;
  }
}
.mb_8 {
  margin-bottom: 8px !important;
}

@media screen and (max-width: 1980px) {
  .mb_8_xlg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_8_lg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_8_mlg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_8_slg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_8_md {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_8_6ls {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_8_5ls {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_8_sm {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_8_msm {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_8_xs {
    margin-bottom: 8px !important;
  }
}
.mt_9 {
  margin-top: 9px !important;
}

@media screen and (max-width: 1980px) {
  .mt_9_xlg {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_9_lg {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_9_mlg {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_9_slg {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_9_md {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_9_6ls {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_9_5ls {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_9_sm {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_9_msm {
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_9_xs {
    margin-top: 9px !important;
  }
}
.mb_9 {
  margin-bottom: 9px !important;
}

@media screen and (max-width: 1980px) {
  .mb_9_xlg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_9_lg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_9_mlg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_9_slg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_9_md {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_9_6ls {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_9_5ls {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_9_sm {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_9_msm {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_9_xs {
    margin-bottom: 9px !important;
  }
}
.mt_10 {
  margin-top: 10px !important;
}

@media screen and (max-width: 1980px) {
  .mt_10_xlg {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_10_lg {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_10_mlg {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_10_slg {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_10_md {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_10_6ls {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_10_5ls {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_10_sm {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_10_msm {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_10_xs {
    margin-top: 10px !important;
  }
}
.mb_10 {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 1980px) {
  .mb_10_xlg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_10_lg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_10_mlg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_10_slg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_10_md {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_10_6ls {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_10_5ls {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_10_sm {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_10_msm {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_10_xs {
    margin-bottom: 10px !important;
  }
}
.mt_11 {
  margin-top: 11px !important;
}

@media screen and (max-width: 1980px) {
  .mt_11_xlg {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_11_lg {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_11_mlg {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_11_slg {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_11_md {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_11_6ls {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_11_5ls {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_11_sm {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_11_msm {
    margin-top: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_11_xs {
    margin-top: 11px !important;
  }
}
.mb_11 {
  margin-bottom: 11px !important;
}

@media screen and (max-width: 1980px) {
  .mb_11_xlg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_11_lg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_11_mlg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_11_slg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_11_md {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_11_6ls {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_11_5ls {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_11_sm {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_11_msm {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_11_xs {
    margin-bottom: 11px !important;
  }
}
.mt_12 {
  margin-top: 12px !important;
}

@media screen and (max-width: 1980px) {
  .mt_12_xlg {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_12_lg {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_12_mlg {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_12_slg {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_12_md {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_12_6ls {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_12_5ls {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_12_sm {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_12_msm {
    margin-top: 12px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_12_xs {
    margin-top: 12px !important;
  }
}
.mb_12 {
  margin-bottom: 12px !important;
}

@media screen and (max-width: 1980px) {
  .mb_12_xlg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_12_lg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_12_mlg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_12_slg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_12_md {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_12_6ls {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_12_5ls {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_12_sm {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_12_msm {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_12_xs {
    margin-bottom: 12px !important;
  }
}
.mt_13 {
  margin-top: 13px !important;
}

@media screen and (max-width: 1980px) {
  .mt_13_xlg {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_13_lg {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_13_mlg {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_13_slg {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_13_md {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_13_6ls {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_13_5ls {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_13_sm {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_13_msm {
    margin-top: 13px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_13_xs {
    margin-top: 13px !important;
  }
}
.mb_13 {
  margin-bottom: 13px !important;
}

@media screen and (max-width: 1980px) {
  .mb_13_xlg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_13_lg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_13_mlg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_13_slg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_13_md {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_13_6ls {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_13_5ls {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_13_sm {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_13_msm {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_13_xs {
    margin-bottom: 13px !important;
  }
}
.mt_14 {
  margin-top: 14px !important;
}

@media screen and (max-width: 1980px) {
  .mt_14_xlg {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_14_lg {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_14_mlg {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_14_slg {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_14_md {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_14_6ls {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_14_5ls {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_14_sm {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_14_msm {
    margin-top: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_14_xs {
    margin-top: 14px !important;
  }
}
.mb_14 {
  margin-bottom: 14px !important;
}

@media screen and (max-width: 1980px) {
  .mb_14_xlg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_14_lg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_14_mlg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_14_slg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_14_md {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_14_6ls {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_14_5ls {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_14_sm {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_14_msm {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_14_xs {
    margin-bottom: 14px !important;
  }
}
.mt_15 {
  margin-top: 15px !important;
}

@media screen and (max-width: 1980px) {
  .mt_15_xlg {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_15_lg {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_15_mlg {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_15_slg {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_15_md {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_15_6ls {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_15_5ls {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_15_sm {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_15_msm {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_15_xs {
    margin-top: 15px !important;
  }
}
.mb_15 {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 1980px) {
  .mb_15_xlg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_15_lg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_15_mlg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_15_slg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_15_md {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_15_6ls {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_15_5ls {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_15_sm {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_15_msm {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_15_xs {
    margin-bottom: 15px !important;
  }
}
.mt_16 {
  margin-top: 16px !important;
}

@media screen and (max-width: 1980px) {
  .mt_16_xlg {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_16_lg {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_16_mlg {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_16_slg {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_16_md {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_16_6ls {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_16_5ls {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_16_sm {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_16_msm {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_16_xs {
    margin-top: 16px !important;
  }
}
.mb_16 {
  margin-bottom: 16px !important;
}

@media screen and (max-width: 1980px) {
  .mb_16_xlg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_16_lg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_16_mlg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_16_slg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_16_md {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_16_6ls {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_16_5ls {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_16_sm {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_16_msm {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_16_xs {
    margin-bottom: 16px !important;
  }
}
.mt_17 {
  margin-top: 17px !important;
}

@media screen and (max-width: 1980px) {
  .mt_17_xlg {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_17_lg {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_17_mlg {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_17_slg {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_17_md {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_17_6ls {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_17_5ls {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_17_sm {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_17_msm {
    margin-top: 17px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_17_xs {
    margin-top: 17px !important;
  }
}
.mb_17 {
  margin-bottom: 17px !important;
}

@media screen and (max-width: 1980px) {
  .mb_17_xlg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_17_lg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_17_mlg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_17_slg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_17_md {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_17_6ls {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_17_5ls {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_17_sm {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_17_msm {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_17_xs {
    margin-bottom: 17px !important;
  }
}
.mt_18 {
  margin-top: 18px !important;
}

@media screen and (max-width: 1980px) {
  .mt_18_xlg {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_18_lg {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_18_mlg {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_18_slg {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_18_md {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_18_6ls {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_18_5ls {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_18_sm {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_18_msm {
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_18_xs {
    margin-top: 18px !important;
  }
}
.mb_18 {
  margin-bottom: 18px !important;
}

@media screen and (max-width: 1980px) {
  .mb_18_xlg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_18_lg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_18_mlg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_18_slg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_18_md {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_18_6ls {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_18_5ls {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_18_sm {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_18_msm {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_18_xs {
    margin-bottom: 18px !important;
  }
}
.mt_19 {
  margin-top: 19px !important;
}

@media screen and (max-width: 1980px) {
  .mt_19_xlg {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_19_lg {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_19_mlg {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_19_slg {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_19_md {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_19_6ls {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_19_5ls {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_19_sm {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_19_msm {
    margin-top: 19px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_19_xs {
    margin-top: 19px !important;
  }
}
.mb_19 {
  margin-bottom: 19px !important;
}

@media screen and (max-width: 1980px) {
  .mb_19_xlg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_19_lg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_19_mlg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_19_slg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_19_md {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_19_6ls {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_19_5ls {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_19_sm {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_19_msm {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_19_xs {
    margin-bottom: 19px !important;
  }
}
.mt_20 {
  margin-top: 20px !important;
}

@media screen and (max-width: 1980px) {
  .mt_20_xlg {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_20_lg {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_20_mlg {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_20_slg {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_20_md {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_20_6ls {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_20_5ls {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_20_sm {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_20_msm {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_20_xs {
    margin-top: 20px !important;
  }
}
.mb_20 {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 1980px) {
  .mb_20_xlg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_20_lg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_20_mlg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_20_slg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_20_md {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_20_6ls {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_20_5ls {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_20_sm {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_20_msm {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_20_xs {
    margin-bottom: 20px !important;
  }
}
.mt_21 {
  margin-top: 21px !important;
}

@media screen and (max-width: 1980px) {
  .mt_21_xlg {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_21_lg {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_21_mlg {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_21_slg {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_21_md {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_21_6ls {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_21_5ls {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_21_sm {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_21_msm {
    margin-top: 21px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_21_xs {
    margin-top: 21px !important;
  }
}
.mb_21 {
  margin-bottom: 21px !important;
}

@media screen and (max-width: 1980px) {
  .mb_21_xlg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_21_lg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_21_mlg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_21_slg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_21_md {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_21_6ls {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_21_5ls {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_21_sm {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_21_msm {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_21_xs {
    margin-bottom: 21px !important;
  }
}
.mt_22 {
  margin-top: 22px !important;
}

@media screen and (max-width: 1980px) {
  .mt_22_xlg {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_22_lg {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_22_mlg {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_22_slg {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_22_md {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_22_6ls {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_22_5ls {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_22_sm {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_22_msm {
    margin-top: 22px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_22_xs {
    margin-top: 22px !important;
  }
}
.mb_22 {
  margin-bottom: 22px !important;
}

@media screen and (max-width: 1980px) {
  .mb_22_xlg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_22_lg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_22_mlg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_22_slg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_22_md {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_22_6ls {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_22_5ls {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_22_sm {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_22_msm {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_22_xs {
    margin-bottom: 22px !important;
  }
}
.mt_23 {
  margin-top: 23px !important;
}

@media screen and (max-width: 1980px) {
  .mt_23_xlg {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_23_lg {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_23_mlg {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_23_slg {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_23_md {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_23_6ls {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_23_5ls {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_23_sm {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_23_msm {
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_23_xs {
    margin-top: 23px !important;
  }
}
.mb_23 {
  margin-bottom: 23px !important;
}

@media screen and (max-width: 1980px) {
  .mb_23_xlg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_23_lg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_23_mlg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_23_slg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_23_md {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_23_6ls {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_23_5ls {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_23_sm {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_23_msm {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_23_xs {
    margin-bottom: 23px !important;
  }
}
.mt_24 {
  margin-top: 24px !important;
}

@media screen and (max-width: 1980px) {
  .mt_24_xlg {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_24_lg {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_24_mlg {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_24_slg {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_24_md {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_24_6ls {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_24_5ls {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_24_sm {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_24_msm {
    margin-top: 24px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_24_xs {
    margin-top: 24px !important;
  }
}
.mb_24 {
  margin-bottom: 24px !important;
}

@media screen and (max-width: 1980px) {
  .mb_24_xlg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_24_lg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_24_mlg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_24_slg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_24_md {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_24_6ls {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_24_5ls {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_24_sm {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_24_msm {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_24_xs {
    margin-bottom: 24px !important;
  }
}
.mt_25 {
  margin-top: 25px !important;
}

@media screen and (max-width: 1980px) {
  .mt_25_xlg {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_25_lg {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_25_mlg {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_25_slg {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_25_md {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_25_6ls {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_25_5ls {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_25_sm {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_25_msm {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_25_xs {
    margin-top: 25px !important;
  }
}
.mb_25 {
  margin-bottom: 25px !important;
}

@media screen and (max-width: 1980px) {
  .mb_25_xlg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_25_lg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_25_mlg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_25_slg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_25_md {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_25_6ls {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_25_5ls {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_25_sm {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_25_msm {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_25_xs {
    margin-bottom: 25px !important;
  }
}
.mt_26 {
  margin-top: 26px !important;
}

@media screen and (max-width: 1980px) {
  .mt_26_xlg {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_26_lg {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_26_mlg {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_26_slg {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_26_md {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_26_6ls {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_26_5ls {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_26_sm {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_26_msm {
    margin-top: 26px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_26_xs {
    margin-top: 26px !important;
  }
}
.mb_26 {
  margin-bottom: 26px !important;
}

@media screen and (max-width: 1980px) {
  .mb_26_xlg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_26_lg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_26_mlg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_26_slg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_26_md {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_26_6ls {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_26_5ls {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_26_sm {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_26_msm {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_26_xs {
    margin-bottom: 26px !important;
  }
}
.mt_27 {
  margin-top: 27px !important;
}

@media screen and (max-width: 1980px) {
  .mt_27_xlg {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_27_lg {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_27_mlg {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_27_slg {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_27_md {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_27_6ls {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_27_5ls {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_27_sm {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_27_msm {
    margin-top: 27px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_27_xs {
    margin-top: 27px !important;
  }
}
.mb_27 {
  margin-bottom: 27px !important;
}

@media screen and (max-width: 1980px) {
  .mb_27_xlg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_27_lg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_27_mlg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_27_slg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_27_md {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_27_6ls {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_27_5ls {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_27_sm {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_27_msm {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_27_xs {
    margin-bottom: 27px !important;
  }
}
.mt_28 {
  margin-top: 28px !important;
}

@media screen and (max-width: 1980px) {
  .mt_28_xlg {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_28_lg {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_28_mlg {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_28_slg {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_28_md {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_28_6ls {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_28_5ls {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_28_sm {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_28_msm {
    margin-top: 28px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_28_xs {
    margin-top: 28px !important;
  }
}
.mb_28 {
  margin-bottom: 28px !important;
}

@media screen and (max-width: 1980px) {
  .mb_28_xlg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_28_lg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_28_mlg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_28_slg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_28_md {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_28_6ls {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_28_5ls {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_28_sm {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_28_msm {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_28_xs {
    margin-bottom: 28px !important;
  }
}
.mt_29 {
  margin-top: 29px !important;
}

@media screen and (max-width: 1980px) {
  .mt_29_xlg {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_29_lg {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_29_mlg {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_29_slg {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_29_md {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_29_6ls {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_29_5ls {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_29_sm {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_29_msm {
    margin-top: 29px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_29_xs {
    margin-top: 29px !important;
  }
}
.mb_29 {
  margin-bottom: 29px !important;
}

@media screen and (max-width: 1980px) {
  .mb_29_xlg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_29_lg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_29_mlg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_29_slg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_29_md {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_29_6ls {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_29_5ls {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_29_sm {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_29_msm {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_29_xs {
    margin-bottom: 29px !important;
  }
}
.mt_30 {
  margin-top: 30px !important;
}

@media screen and (max-width: 1980px) {
  .mt_30_xlg {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_30_lg {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_30_mlg {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_30_slg {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_30_md {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_30_6ls {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_30_5ls {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_30_sm {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_30_msm {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_30_xs {
    margin-top: 30px !important;
  }
}
.mb_30 {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 1980px) {
  .mb_30_xlg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_30_lg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_30_mlg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_30_slg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_30_md {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_30_6ls {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_30_5ls {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_30_sm {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_30_msm {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_30_xs {
    margin-bottom: 30px !important;
  }
}
.mt_31 {
  margin-top: 31px !important;
}

@media screen and (max-width: 1980px) {
  .mt_31_xlg {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_31_lg {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_31_mlg {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_31_slg {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_31_md {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_31_6ls {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_31_5ls {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_31_sm {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_31_msm {
    margin-top: 31px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_31_xs {
    margin-top: 31px !important;
  }
}
.mb_31 {
  margin-bottom: 31px !important;
}

@media screen and (max-width: 1980px) {
  .mb_31_xlg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_31_lg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_31_mlg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_31_slg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_31_md {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_31_6ls {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_31_5ls {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_31_sm {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_31_msm {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_31_xs {
    margin-bottom: 31px !important;
  }
}
.mt_32 {
  margin-top: 32px !important;
}

@media screen and (max-width: 1980px) {
  .mt_32_xlg {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_32_lg {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_32_mlg {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_32_slg {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_32_md {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_32_6ls {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_32_5ls {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_32_sm {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_32_msm {
    margin-top: 32px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_32_xs {
    margin-top: 32px !important;
  }
}
.mb_32 {
  margin-bottom: 32px !important;
}

@media screen and (max-width: 1980px) {
  .mb_32_xlg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_32_lg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_32_mlg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_32_slg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_32_md {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_32_6ls {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_32_5ls {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_32_sm {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_32_msm {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_32_xs {
    margin-bottom: 32px !important;
  }
}
.mt_33 {
  margin-top: 33px !important;
}

@media screen and (max-width: 1980px) {
  .mt_33_xlg {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_33_lg {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_33_mlg {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_33_slg {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_33_md {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_33_6ls {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_33_5ls {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_33_sm {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_33_msm {
    margin-top: 33px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_33_xs {
    margin-top: 33px !important;
  }
}
.mb_33 {
  margin-bottom: 33px !important;
}

@media screen and (max-width: 1980px) {
  .mb_33_xlg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_33_lg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_33_mlg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_33_slg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_33_md {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_33_6ls {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_33_5ls {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_33_sm {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_33_msm {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_33_xs {
    margin-bottom: 33px !important;
  }
}
.mt_34 {
  margin-top: 34px !important;
}

@media screen and (max-width: 1980px) {
  .mt_34_xlg {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_34_lg {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_34_mlg {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_34_slg {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_34_md {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_34_6ls {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_34_5ls {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_34_sm {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_34_msm {
    margin-top: 34px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_34_xs {
    margin-top: 34px !important;
  }
}
.mb_34 {
  margin-bottom: 34px !important;
}

@media screen and (max-width: 1980px) {
  .mb_34_xlg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_34_lg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_34_mlg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_34_slg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_34_md {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_34_6ls {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_34_5ls {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_34_sm {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_34_msm {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_34_xs {
    margin-bottom: 34px !important;
  }
}
.mt_35 {
  margin-top: 35px !important;
}

@media screen and (max-width: 1980px) {
  .mt_35_xlg {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_35_lg {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_35_mlg {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_35_slg {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_35_md {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_35_6ls {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_35_5ls {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_35_sm {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_35_msm {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_35_xs {
    margin-top: 35px !important;
  }
}
.mb_35 {
  margin-bottom: 35px !important;
}

@media screen and (max-width: 1980px) {
  .mb_35_xlg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_35_lg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_35_mlg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_35_slg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_35_md {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_35_6ls {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_35_5ls {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_35_sm {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_35_msm {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_35_xs {
    margin-bottom: 35px !important;
  }
}
.mt_36 {
  margin-top: 36px !important;
}

@media screen and (max-width: 1980px) {
  .mt_36_xlg {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_36_lg {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_36_mlg {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_36_slg {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_36_md {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_36_6ls {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_36_5ls {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_36_sm {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_36_msm {
    margin-top: 36px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_36_xs {
    margin-top: 36px !important;
  }
}
.mb_36 {
  margin-bottom: 36px !important;
}

@media screen and (max-width: 1980px) {
  .mb_36_xlg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_36_lg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_36_mlg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_36_slg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_36_md {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_36_6ls {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_36_5ls {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_36_sm {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_36_msm {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_36_xs {
    margin-bottom: 36px !important;
  }
}
.mt_37 {
  margin-top: 37px !important;
}

@media screen and (max-width: 1980px) {
  .mt_37_xlg {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_37_lg {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_37_mlg {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_37_slg {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_37_md {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_37_6ls {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_37_5ls {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_37_sm {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_37_msm {
    margin-top: 37px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_37_xs {
    margin-top: 37px !important;
  }
}
.mb_37 {
  margin-bottom: 37px !important;
}

@media screen and (max-width: 1980px) {
  .mb_37_xlg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_37_lg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_37_mlg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_37_slg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_37_md {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_37_6ls {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_37_5ls {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_37_sm {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_37_msm {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_37_xs {
    margin-bottom: 37px !important;
  }
}
.mt_38 {
  margin-top: 38px !important;
}

@media screen and (max-width: 1980px) {
  .mt_38_xlg {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_38_lg {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_38_mlg {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_38_slg {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_38_md {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_38_6ls {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_38_5ls {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_38_sm {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_38_msm {
    margin-top: 38px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_38_xs {
    margin-top: 38px !important;
  }
}
.mb_38 {
  margin-bottom: 38px !important;
}

@media screen and (max-width: 1980px) {
  .mb_38_xlg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_38_lg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_38_mlg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_38_slg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_38_md {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_38_6ls {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_38_5ls {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_38_sm {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_38_msm {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_38_xs {
    margin-bottom: 38px !important;
  }
}
.mt_39 {
  margin-top: 39px !important;
}

@media screen and (max-width: 1980px) {
  .mt_39_xlg {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_39_lg {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_39_mlg {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_39_slg {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_39_md {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_39_6ls {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_39_5ls {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_39_sm {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_39_msm {
    margin-top: 39px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_39_xs {
    margin-top: 39px !important;
  }
}
.mb_39 {
  margin-bottom: 39px !important;
}

@media screen and (max-width: 1980px) {
  .mb_39_xlg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_39_lg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_39_mlg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_39_slg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_39_md {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_39_6ls {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_39_5ls {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_39_sm {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_39_msm {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_39_xs {
    margin-bottom: 39px !important;
  }
}
.mt_40 {
  margin-top: 40px !important;
}

@media screen and (max-width: 1980px) {
  .mt_40_xlg {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_40_lg {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_40_mlg {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_40_slg {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_40_md {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_40_6ls {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_40_5ls {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_40_sm {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_40_msm {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_40_xs {
    margin-top: 40px !important;
  }
}
.mb_40 {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 1980px) {
  .mb_40_xlg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_40_lg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_40_mlg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_40_slg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_40_md {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_40_6ls {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_40_5ls {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_40_sm {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_40_msm {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_40_xs {
    margin-bottom: 40px !important;
  }
}
.mt_41 {
  margin-top: 41px !important;
}

@media screen and (max-width: 1980px) {
  .mt_41_xlg {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_41_lg {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_41_mlg {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_41_slg {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_41_md {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_41_6ls {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_41_5ls {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_41_sm {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_41_msm {
    margin-top: 41px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_41_xs {
    margin-top: 41px !important;
  }
}
.mb_41 {
  margin-bottom: 41px !important;
}

@media screen and (max-width: 1980px) {
  .mb_41_xlg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_41_lg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_41_mlg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_41_slg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_41_md {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_41_6ls {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_41_5ls {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_41_sm {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_41_msm {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_41_xs {
    margin-bottom: 41px !important;
  }
}
.mt_42 {
  margin-top: 42px !important;
}

@media screen and (max-width: 1980px) {
  .mt_42_xlg {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_42_lg {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_42_mlg {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_42_slg {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_42_md {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_42_6ls {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_42_5ls {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_42_sm {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_42_msm {
    margin-top: 42px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_42_xs {
    margin-top: 42px !important;
  }
}
.mb_42 {
  margin-bottom: 42px !important;
}

@media screen and (max-width: 1980px) {
  .mb_42_xlg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_42_lg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_42_mlg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_42_slg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_42_md {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_42_6ls {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_42_5ls {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_42_sm {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_42_msm {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_42_xs {
    margin-bottom: 42px !important;
  }
}
.mt_43 {
  margin-top: 43px !important;
}

@media screen and (max-width: 1980px) {
  .mt_43_xlg {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_43_lg {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_43_mlg {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_43_slg {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_43_md {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_43_6ls {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_43_5ls {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_43_sm {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_43_msm {
    margin-top: 43px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_43_xs {
    margin-top: 43px !important;
  }
}
.mb_43 {
  margin-bottom: 43px !important;
}

@media screen and (max-width: 1980px) {
  .mb_43_xlg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_43_lg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_43_mlg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_43_slg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_43_md {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_43_6ls {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_43_5ls {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_43_sm {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_43_msm {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_43_xs {
    margin-bottom: 43px !important;
  }
}
.mt_44 {
  margin-top: 44px !important;
}

@media screen and (max-width: 1980px) {
  .mt_44_xlg {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_44_lg {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_44_mlg {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_44_slg {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_44_md {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_44_6ls {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_44_5ls {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_44_sm {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_44_msm {
    margin-top: 44px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_44_xs {
    margin-top: 44px !important;
  }
}
.mb_44 {
  margin-bottom: 44px !important;
}

@media screen and (max-width: 1980px) {
  .mb_44_xlg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_44_lg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_44_mlg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_44_slg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_44_md {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_44_6ls {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_44_5ls {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_44_sm {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_44_msm {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_44_xs {
    margin-bottom: 44px !important;
  }
}
.mt_45 {
  margin-top: 45px !important;
}

@media screen and (max-width: 1980px) {
  .mt_45_xlg {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_45_lg {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_45_mlg {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_45_slg {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_45_md {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_45_6ls {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_45_5ls {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_45_sm {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_45_msm {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_45_xs {
    margin-top: 45px !important;
  }
}
.mb_45 {
  margin-bottom: 45px !important;
}

@media screen and (max-width: 1980px) {
  .mb_45_xlg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_45_lg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_45_mlg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_45_slg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_45_md {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_45_6ls {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_45_5ls {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_45_sm {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_45_msm {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_45_xs {
    margin-bottom: 45px !important;
  }
}
.mt_46 {
  margin-top: 46px !important;
}

@media screen and (max-width: 1980px) {
  .mt_46_xlg {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_46_lg {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_46_mlg {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_46_slg {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_46_md {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_46_6ls {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_46_5ls {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_46_sm {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_46_msm {
    margin-top: 46px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_46_xs {
    margin-top: 46px !important;
  }
}
.mb_46 {
  margin-bottom: 46px !important;
}

@media screen and (max-width: 1980px) {
  .mb_46_xlg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_46_lg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_46_mlg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_46_slg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_46_md {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_46_6ls {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_46_5ls {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_46_sm {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_46_msm {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_46_xs {
    margin-bottom: 46px !important;
  }
}
.mt_47 {
  margin-top: 47px !important;
}

@media screen and (max-width: 1980px) {
  .mt_47_xlg {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_47_lg {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_47_mlg {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_47_slg {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_47_md {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_47_6ls {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_47_5ls {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_47_sm {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_47_msm {
    margin-top: 47px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_47_xs {
    margin-top: 47px !important;
  }
}
.mb_47 {
  margin-bottom: 47px !important;
}

@media screen and (max-width: 1980px) {
  .mb_47_xlg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_47_lg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_47_mlg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_47_slg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_47_md {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_47_6ls {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_47_5ls {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_47_sm {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_47_msm {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_47_xs {
    margin-bottom: 47px !important;
  }
}
.mt_48 {
  margin-top: 48px !important;
}

@media screen and (max-width: 1980px) {
  .mt_48_xlg {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_48_lg {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_48_mlg {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_48_slg {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_48_md {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_48_6ls {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_48_5ls {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_48_sm {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_48_msm {
    margin-top: 48px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_48_xs {
    margin-top: 48px !important;
  }
}
.mb_48 {
  margin-bottom: 48px !important;
}

@media screen and (max-width: 1980px) {
  .mb_48_xlg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_48_lg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_48_mlg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_48_slg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_48_md {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_48_6ls {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_48_5ls {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_48_sm {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_48_msm {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_48_xs {
    margin-bottom: 48px !important;
  }
}
.mt_49 {
  margin-top: 49px !important;
}

@media screen and (max-width: 1980px) {
  .mt_49_xlg {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_49_lg {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_49_mlg {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_49_slg {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_49_md {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_49_6ls {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_49_5ls {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_49_sm {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_49_msm {
    margin-top: 49px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_49_xs {
    margin-top: 49px !important;
  }
}
.mb_49 {
  margin-bottom: 49px !important;
}

@media screen and (max-width: 1980px) {
  .mb_49_xlg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_49_lg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_49_mlg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_49_slg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_49_md {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_49_6ls {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_49_5ls {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_49_sm {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_49_msm {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_49_xs {
    margin-bottom: 49px !important;
  }
}
.mt_50 {
  margin-top: 50px !important;
}

@media screen and (max-width: 1980px) {
  .mt_50_xlg {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_50_lg {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_50_mlg {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_50_slg {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_50_md {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_50_6ls {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_50_5ls {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_50_sm {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_50_msm {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_50_xs {
    margin-top: 50px !important;
  }
}
.mb_50 {
  margin-bottom: 50px !important;
}

@media screen and (max-width: 1980px) {
  .mb_50_xlg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_50_lg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_50_mlg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_50_slg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_50_md {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_50_6ls {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_50_5ls {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_50_sm {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_50_msm {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_50_xs {
    margin-bottom: 50px !important;
  }
}
.mt_51 {
  margin-top: 51px !important;
}

@media screen and (max-width: 1980px) {
  .mt_51_xlg {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_51_lg {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_51_mlg {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_51_slg {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_51_md {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_51_6ls {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_51_5ls {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_51_sm {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_51_msm {
    margin-top: 51px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_51_xs {
    margin-top: 51px !important;
  }
}
.mb_51 {
  margin-bottom: 51px !important;
}

@media screen and (max-width: 1980px) {
  .mb_51_xlg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_51_lg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_51_mlg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_51_slg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_51_md {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_51_6ls {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_51_5ls {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_51_sm {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_51_msm {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_51_xs {
    margin-bottom: 51px !important;
  }
}
.mt_52 {
  margin-top: 52px !important;
}

@media screen and (max-width: 1980px) {
  .mt_52_xlg {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_52_lg {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_52_mlg {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_52_slg {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_52_md {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_52_6ls {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_52_5ls {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_52_sm {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_52_msm {
    margin-top: 52px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_52_xs {
    margin-top: 52px !important;
  }
}
.mb_52 {
  margin-bottom: 52px !important;
}

@media screen and (max-width: 1980px) {
  .mb_52_xlg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_52_lg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_52_mlg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_52_slg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_52_md {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_52_6ls {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_52_5ls {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_52_sm {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_52_msm {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_52_xs {
    margin-bottom: 52px !important;
  }
}
.mt_53 {
  margin-top: 53px !important;
}

@media screen and (max-width: 1980px) {
  .mt_53_xlg {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_53_lg {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_53_mlg {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_53_slg {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_53_md {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_53_6ls {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_53_5ls {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_53_sm {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_53_msm {
    margin-top: 53px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_53_xs {
    margin-top: 53px !important;
  }
}
.mb_53 {
  margin-bottom: 53px !important;
}

@media screen and (max-width: 1980px) {
  .mb_53_xlg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_53_lg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_53_mlg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_53_slg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_53_md {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_53_6ls {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_53_5ls {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_53_sm {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_53_msm {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_53_xs {
    margin-bottom: 53px !important;
  }
}
.mt_54 {
  margin-top: 54px !important;
}

@media screen and (max-width: 1980px) {
  .mt_54_xlg {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_54_lg {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_54_mlg {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_54_slg {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_54_md {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_54_6ls {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_54_5ls {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_54_sm {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_54_msm {
    margin-top: 54px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_54_xs {
    margin-top: 54px !important;
  }
}
.mb_54 {
  margin-bottom: 54px !important;
}

@media screen and (max-width: 1980px) {
  .mb_54_xlg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_54_lg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_54_mlg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_54_slg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_54_md {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_54_6ls {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_54_5ls {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_54_sm {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_54_msm {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_54_xs {
    margin-bottom: 54px !important;
  }
}
.mt_55 {
  margin-top: 55px !important;
}

@media screen and (max-width: 1980px) {
  .mt_55_xlg {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_55_lg {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_55_mlg {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_55_slg {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_55_md {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_55_6ls {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_55_5ls {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_55_sm {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_55_msm {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_55_xs {
    margin-top: 55px !important;
  }
}
.mb_55 {
  margin-bottom: 55px !important;
}

@media screen and (max-width: 1980px) {
  .mb_55_xlg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_55_lg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_55_mlg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_55_slg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_55_md {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_55_6ls {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_55_5ls {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_55_sm {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_55_msm {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_55_xs {
    margin-bottom: 55px !important;
  }
}
.mt_56 {
  margin-top: 56px !important;
}

@media screen and (max-width: 1980px) {
  .mt_56_xlg {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_56_lg {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_56_mlg {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_56_slg {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_56_md {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_56_6ls {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_56_5ls {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_56_sm {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_56_msm {
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_56_xs {
    margin-top: 56px !important;
  }
}
.mb_56 {
  margin-bottom: 56px !important;
}

@media screen and (max-width: 1980px) {
  .mb_56_xlg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_56_lg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_56_mlg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_56_slg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_56_md {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_56_6ls {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_56_5ls {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_56_sm {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_56_msm {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_56_xs {
    margin-bottom: 56px !important;
  }
}
.mt_57 {
  margin-top: 57px !important;
}

@media screen and (max-width: 1980px) {
  .mt_57_xlg {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_57_lg {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_57_mlg {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_57_slg {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_57_md {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_57_6ls {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_57_5ls {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_57_sm {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_57_msm {
    margin-top: 57px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_57_xs {
    margin-top: 57px !important;
  }
}
.mb_57 {
  margin-bottom: 57px !important;
}

@media screen and (max-width: 1980px) {
  .mb_57_xlg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_57_lg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_57_mlg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_57_slg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_57_md {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_57_6ls {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_57_5ls {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_57_sm {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_57_msm {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_57_xs {
    margin-bottom: 57px !important;
  }
}
.mt_58 {
  margin-top: 58px !important;
}

@media screen and (max-width: 1980px) {
  .mt_58_xlg {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_58_lg {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_58_mlg {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_58_slg {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_58_md {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_58_6ls {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_58_5ls {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_58_sm {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_58_msm {
    margin-top: 58px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_58_xs {
    margin-top: 58px !important;
  }
}
.mb_58 {
  margin-bottom: 58px !important;
}

@media screen and (max-width: 1980px) {
  .mb_58_xlg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_58_lg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_58_mlg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_58_slg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_58_md {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_58_6ls {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_58_5ls {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_58_sm {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_58_msm {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_58_xs {
    margin-bottom: 58px !important;
  }
}
.mt_59 {
  margin-top: 59px !important;
}

@media screen and (max-width: 1980px) {
  .mt_59_xlg {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_59_lg {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_59_mlg {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_59_slg {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_59_md {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_59_6ls {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_59_5ls {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_59_sm {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_59_msm {
    margin-top: 59px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_59_xs {
    margin-top: 59px !important;
  }
}
.mb_59 {
  margin-bottom: 59px !important;
}

@media screen and (max-width: 1980px) {
  .mb_59_xlg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_59_lg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_59_mlg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_59_slg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_59_md {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_59_6ls {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_59_5ls {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_59_sm {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_59_msm {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_59_xs {
    margin-bottom: 59px !important;
  }
}
.mt_60 {
  margin-top: 60px !important;
}

@media screen and (max-width: 1980px) {
  .mt_60_xlg {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_60_lg {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_60_mlg {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_60_slg {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_60_md {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_60_6ls {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_60_5ls {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_60_sm {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_60_msm {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_60_xs {
    margin-top: 60px !important;
  }
}
.mb_60 {
  margin-bottom: 60px !important;
}

@media screen and (max-width: 1980px) {
  .mb_60_xlg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_60_lg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_60_mlg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_60_slg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_60_md {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_60_6ls {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_60_5ls {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_60_sm {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_60_msm {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_60_xs {
    margin-bottom: 60px !important;
  }
}
.mt_61 {
  margin-top: 61px !important;
}

@media screen and (max-width: 1980px) {
  .mt_61_xlg {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_61_lg {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_61_mlg {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_61_slg {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_61_md {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_61_6ls {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_61_5ls {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_61_sm {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_61_msm {
    margin-top: 61px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_61_xs {
    margin-top: 61px !important;
  }
}
.mb_61 {
  margin-bottom: 61px !important;
}

@media screen and (max-width: 1980px) {
  .mb_61_xlg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_61_lg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_61_mlg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_61_slg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_61_md {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_61_6ls {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_61_5ls {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_61_sm {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_61_msm {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_61_xs {
    margin-bottom: 61px !important;
  }
}
.mt_62 {
  margin-top: 62px !important;
}

@media screen and (max-width: 1980px) {
  .mt_62_xlg {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_62_lg {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_62_mlg {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_62_slg {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_62_md {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_62_6ls {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_62_5ls {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_62_sm {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_62_msm {
    margin-top: 62px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_62_xs {
    margin-top: 62px !important;
  }
}
.mb_62 {
  margin-bottom: 62px !important;
}

@media screen and (max-width: 1980px) {
  .mb_62_xlg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_62_lg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_62_mlg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_62_slg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_62_md {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_62_6ls {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_62_5ls {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_62_sm {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_62_msm {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_62_xs {
    margin-bottom: 62px !important;
  }
}
.mt_63 {
  margin-top: 63px !important;
}

@media screen and (max-width: 1980px) {
  .mt_63_xlg {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_63_lg {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_63_mlg {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_63_slg {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_63_md {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_63_6ls {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_63_5ls {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_63_sm {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_63_msm {
    margin-top: 63px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_63_xs {
    margin-top: 63px !important;
  }
}
.mb_63 {
  margin-bottom: 63px !important;
}

@media screen and (max-width: 1980px) {
  .mb_63_xlg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_63_lg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_63_mlg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_63_slg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_63_md {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_63_6ls {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_63_5ls {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_63_sm {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_63_msm {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_63_xs {
    margin-bottom: 63px !important;
  }
}
.mt_64 {
  margin-top: 64px !important;
}

@media screen and (max-width: 1980px) {
  .mt_64_xlg {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_64_lg {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_64_mlg {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_64_slg {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_64_md {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_64_6ls {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_64_5ls {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_64_sm {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_64_msm {
    margin-top: 64px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_64_xs {
    margin-top: 64px !important;
  }
}
.mb_64 {
  margin-bottom: 64px !important;
}

@media screen and (max-width: 1980px) {
  .mb_64_xlg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_64_lg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_64_mlg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_64_slg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_64_md {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_64_6ls {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_64_5ls {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_64_sm {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_64_msm {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_64_xs {
    margin-bottom: 64px !important;
  }
}
.mt_65 {
  margin-top: 65px !important;
}

@media screen and (max-width: 1980px) {
  .mt_65_xlg {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_65_lg {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_65_mlg {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_65_slg {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_65_md {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_65_6ls {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_65_5ls {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_65_sm {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_65_msm {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_65_xs {
    margin-top: 65px !important;
  }
}
.mb_65 {
  margin-bottom: 65px !important;
}

@media screen and (max-width: 1980px) {
  .mb_65_xlg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_65_lg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_65_mlg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_65_slg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_65_md {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_65_6ls {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_65_5ls {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_65_sm {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_65_msm {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_65_xs {
    margin-bottom: 65px !important;
  }
}
.mt_66 {
  margin-top: 66px !important;
}

@media screen and (max-width: 1980px) {
  .mt_66_xlg {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_66_lg {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_66_mlg {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_66_slg {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_66_md {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_66_6ls {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_66_5ls {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_66_sm {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_66_msm {
    margin-top: 66px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_66_xs {
    margin-top: 66px !important;
  }
}
.mb_66 {
  margin-bottom: 66px !important;
}

@media screen and (max-width: 1980px) {
  .mb_66_xlg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_66_lg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_66_mlg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_66_slg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_66_md {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_66_6ls {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_66_5ls {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_66_sm {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_66_msm {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_66_xs {
    margin-bottom: 66px !important;
  }
}
.mt_67 {
  margin-top: 67px !important;
}

@media screen and (max-width: 1980px) {
  .mt_67_xlg {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_67_lg {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_67_mlg {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_67_slg {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_67_md {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_67_6ls {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_67_5ls {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_67_sm {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_67_msm {
    margin-top: 67px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_67_xs {
    margin-top: 67px !important;
  }
}
.mb_67 {
  margin-bottom: 67px !important;
}

@media screen and (max-width: 1980px) {
  .mb_67_xlg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_67_lg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_67_mlg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_67_slg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_67_md {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_67_6ls {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_67_5ls {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_67_sm {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_67_msm {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_67_xs {
    margin-bottom: 67px !important;
  }
}
.mt_68 {
  margin-top: 68px !important;
}

@media screen and (max-width: 1980px) {
  .mt_68_xlg {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_68_lg {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_68_mlg {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_68_slg {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_68_md {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_68_6ls {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_68_5ls {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_68_sm {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_68_msm {
    margin-top: 68px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_68_xs {
    margin-top: 68px !important;
  }
}
.mb_68 {
  margin-bottom: 68px !important;
}

@media screen and (max-width: 1980px) {
  .mb_68_xlg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_68_lg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_68_mlg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_68_slg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_68_md {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_68_6ls {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_68_5ls {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_68_sm {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_68_msm {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_68_xs {
    margin-bottom: 68px !important;
  }
}
.mt_69 {
  margin-top: 69px !important;
}

@media screen and (max-width: 1980px) {
  .mt_69_xlg {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_69_lg {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_69_mlg {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_69_slg {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_69_md {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_69_6ls {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_69_5ls {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_69_sm {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_69_msm {
    margin-top: 69px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_69_xs {
    margin-top: 69px !important;
  }
}
.mb_69 {
  margin-bottom: 69px !important;
}

@media screen and (max-width: 1980px) {
  .mb_69_xlg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_69_lg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_69_mlg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_69_slg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_69_md {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_69_6ls {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_69_5ls {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_69_sm {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_69_msm {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_69_xs {
    margin-bottom: 69px !important;
  }
}
.mt_70 {
  margin-top: 70px !important;
}

@media screen and (max-width: 1980px) {
  .mt_70_xlg {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_70_lg {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_70_mlg {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_70_slg {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_70_md {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_70_6ls {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_70_5ls {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_70_sm {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_70_msm {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_70_xs {
    margin-top: 70px !important;
  }
}
.mb_70 {
  margin-bottom: 70px !important;
}

@media screen and (max-width: 1980px) {
  .mb_70_xlg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_70_lg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_70_mlg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_70_slg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_70_md {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_70_6ls {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_70_5ls {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_70_sm {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_70_msm {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_70_xs {
    margin-bottom: 70px !important;
  }
}
.mt_71 {
  margin-top: 71px !important;
}

@media screen and (max-width: 1980px) {
  .mt_71_xlg {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_71_lg {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_71_mlg {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_71_slg {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_71_md {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_71_6ls {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_71_5ls {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_71_sm {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_71_msm {
    margin-top: 71px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_71_xs {
    margin-top: 71px !important;
  }
}
.mb_71 {
  margin-bottom: 71px !important;
}

@media screen and (max-width: 1980px) {
  .mb_71_xlg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_71_lg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_71_mlg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_71_slg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_71_md {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_71_6ls {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_71_5ls {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_71_sm {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_71_msm {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_71_xs {
    margin-bottom: 71px !important;
  }
}
.mt_72 {
  margin-top: 72px !important;
}

@media screen and (max-width: 1980px) {
  .mt_72_xlg {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_72_lg {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_72_mlg {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_72_slg {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_72_md {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_72_6ls {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_72_5ls {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_72_sm {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_72_msm {
    margin-top: 72px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_72_xs {
    margin-top: 72px !important;
  }
}
.mb_72 {
  margin-bottom: 72px !important;
}

@media screen and (max-width: 1980px) {
  .mb_72_xlg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_72_lg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_72_mlg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_72_slg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_72_md {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_72_6ls {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_72_5ls {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_72_sm {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_72_msm {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_72_xs {
    margin-bottom: 72px !important;
  }
}
.mt_73 {
  margin-top: 73px !important;
}

@media screen and (max-width: 1980px) {
  .mt_73_xlg {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_73_lg {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_73_mlg {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_73_slg {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_73_md {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_73_6ls {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_73_5ls {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_73_sm {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_73_msm {
    margin-top: 73px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_73_xs {
    margin-top: 73px !important;
  }
}
.mb_73 {
  margin-bottom: 73px !important;
}

@media screen and (max-width: 1980px) {
  .mb_73_xlg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_73_lg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_73_mlg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_73_slg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_73_md {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_73_6ls {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_73_5ls {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_73_sm {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_73_msm {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_73_xs {
    margin-bottom: 73px !important;
  }
}
.mt_74 {
  margin-top: 74px !important;
}

@media screen and (max-width: 1980px) {
  .mt_74_xlg {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_74_lg {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_74_mlg {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_74_slg {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_74_md {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_74_6ls {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_74_5ls {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_74_sm {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_74_msm {
    margin-top: 74px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_74_xs {
    margin-top: 74px !important;
  }
}
.mb_74 {
  margin-bottom: 74px !important;
}

@media screen and (max-width: 1980px) {
  .mb_74_xlg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_74_lg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_74_mlg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_74_slg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_74_md {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_74_6ls {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_74_5ls {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_74_sm {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_74_msm {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_74_xs {
    margin-bottom: 74px !important;
  }
}
.mt_75 {
  margin-top: 75px !important;
}

@media screen and (max-width: 1980px) {
  .mt_75_xlg {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_75_lg {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_75_mlg {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_75_slg {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_75_md {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_75_6ls {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_75_5ls {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_75_sm {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_75_msm {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_75_xs {
    margin-top: 75px !important;
  }
}
.mb_75 {
  margin-bottom: 75px !important;
}

@media screen and (max-width: 1980px) {
  .mb_75_xlg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_75_lg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_75_mlg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_75_slg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_75_md {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_75_6ls {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_75_5ls {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_75_sm {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_75_msm {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_75_xs {
    margin-bottom: 75px !important;
  }
}
.mt_76 {
  margin-top: 76px !important;
}

@media screen and (max-width: 1980px) {
  .mt_76_xlg {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_76_lg {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_76_mlg {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_76_slg {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_76_md {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_76_6ls {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_76_5ls {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_76_sm {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_76_msm {
    margin-top: 76px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_76_xs {
    margin-top: 76px !important;
  }
}
.mb_76 {
  margin-bottom: 76px !important;
}

@media screen and (max-width: 1980px) {
  .mb_76_xlg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_76_lg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_76_mlg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_76_slg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_76_md {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_76_6ls {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_76_5ls {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_76_sm {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_76_msm {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_76_xs {
    margin-bottom: 76px !important;
  }
}
.mt_77 {
  margin-top: 77px !important;
}

@media screen and (max-width: 1980px) {
  .mt_77_xlg {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_77_lg {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_77_mlg {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_77_slg {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_77_md {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_77_6ls {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_77_5ls {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_77_sm {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_77_msm {
    margin-top: 77px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_77_xs {
    margin-top: 77px !important;
  }
}
.mb_77 {
  margin-bottom: 77px !important;
}

@media screen and (max-width: 1980px) {
  .mb_77_xlg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_77_lg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_77_mlg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_77_slg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_77_md {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_77_6ls {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_77_5ls {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_77_sm {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_77_msm {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_77_xs {
    margin-bottom: 77px !important;
  }
}
.mt_78 {
  margin-top: 78px !important;
}

@media screen and (max-width: 1980px) {
  .mt_78_xlg {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_78_lg {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_78_mlg {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_78_slg {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_78_md {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_78_6ls {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_78_5ls {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_78_sm {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_78_msm {
    margin-top: 78px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_78_xs {
    margin-top: 78px !important;
  }
}
.mb_78 {
  margin-bottom: 78px !important;
}

@media screen and (max-width: 1980px) {
  .mb_78_xlg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_78_lg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_78_mlg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_78_slg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_78_md {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_78_6ls {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_78_5ls {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_78_sm {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_78_msm {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_78_xs {
    margin-bottom: 78px !important;
  }
}
.mt_79 {
  margin-top: 79px !important;
}

@media screen and (max-width: 1980px) {
  .mt_79_xlg {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_79_lg {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_79_mlg {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_79_slg {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_79_md {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_79_6ls {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_79_5ls {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_79_sm {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_79_msm {
    margin-top: 79px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_79_xs {
    margin-top: 79px !important;
  }
}
.mb_79 {
  margin-bottom: 79px !important;
}

@media screen and (max-width: 1980px) {
  .mb_79_xlg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_79_lg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_79_mlg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_79_slg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_79_md {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_79_6ls {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_79_5ls {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_79_sm {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_79_msm {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_79_xs {
    margin-bottom: 79px !important;
  }
}
.mt_80 {
  margin-top: 80px !important;
}

@media screen and (max-width: 1980px) {
  .mt_80_xlg {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_80_lg {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_80_mlg {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_80_slg {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_80_md {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_80_6ls {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_80_5ls {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_80_sm {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_80_msm {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_80_xs {
    margin-top: 80px !important;
  }
}
.mb_80 {
  margin-bottom: 80px !important;
}

@media screen and (max-width: 1980px) {
  .mb_80_xlg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_80_lg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_80_mlg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_80_slg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_80_md {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_80_6ls {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_80_5ls {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_80_sm {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_80_msm {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_80_xs {
    margin-bottom: 80px !important;
  }
}
.mt_81 {
  margin-top: 81px !important;
}

@media screen and (max-width: 1980px) {
  .mt_81_xlg {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_81_lg {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_81_mlg {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_81_slg {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_81_md {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_81_6ls {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_81_5ls {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_81_sm {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_81_msm {
    margin-top: 81px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_81_xs {
    margin-top: 81px !important;
  }
}
.mb_81 {
  margin-bottom: 81px !important;
}

@media screen and (max-width: 1980px) {
  .mb_81_xlg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_81_lg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_81_mlg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_81_slg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_81_md {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_81_6ls {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_81_5ls {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_81_sm {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_81_msm {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_81_xs {
    margin-bottom: 81px !important;
  }
}
.mt_82 {
  margin-top: 82px !important;
}

@media screen and (max-width: 1980px) {
  .mt_82_xlg {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_82_lg {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_82_mlg {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_82_slg {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_82_md {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_82_6ls {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_82_5ls {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_82_sm {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_82_msm {
    margin-top: 82px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_82_xs {
    margin-top: 82px !important;
  }
}
.mb_82 {
  margin-bottom: 82px !important;
}

@media screen and (max-width: 1980px) {
  .mb_82_xlg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_82_lg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_82_mlg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_82_slg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_82_md {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_82_6ls {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_82_5ls {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_82_sm {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_82_msm {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_82_xs {
    margin-bottom: 82px !important;
  }
}
.mt_83 {
  margin-top: 83px !important;
}

@media screen and (max-width: 1980px) {
  .mt_83_xlg {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_83_lg {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_83_mlg {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_83_slg {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_83_md {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_83_6ls {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_83_5ls {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_83_sm {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_83_msm {
    margin-top: 83px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_83_xs {
    margin-top: 83px !important;
  }
}
.mb_83 {
  margin-bottom: 83px !important;
}

@media screen and (max-width: 1980px) {
  .mb_83_xlg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_83_lg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_83_mlg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_83_slg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_83_md {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_83_6ls {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_83_5ls {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_83_sm {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_83_msm {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_83_xs {
    margin-bottom: 83px !important;
  }
}
.mt_84 {
  margin-top: 84px !important;
}

@media screen and (max-width: 1980px) {
  .mt_84_xlg {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_84_lg {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_84_mlg {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_84_slg {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_84_md {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_84_6ls {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_84_5ls {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_84_sm {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_84_msm {
    margin-top: 84px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_84_xs {
    margin-top: 84px !important;
  }
}
.mb_84 {
  margin-bottom: 84px !important;
}

@media screen and (max-width: 1980px) {
  .mb_84_xlg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_84_lg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_84_mlg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_84_slg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_84_md {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_84_6ls {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_84_5ls {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_84_sm {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_84_msm {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_84_xs {
    margin-bottom: 84px !important;
  }
}
.mt_85 {
  margin-top: 85px !important;
}

@media screen and (max-width: 1980px) {
  .mt_85_xlg {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_85_lg {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_85_mlg {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_85_slg {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_85_md {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_85_6ls {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_85_5ls {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_85_sm {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_85_msm {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_85_xs {
    margin-top: 85px !important;
  }
}
.mb_85 {
  margin-bottom: 85px !important;
}

@media screen and (max-width: 1980px) {
  .mb_85_xlg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_85_lg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_85_mlg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_85_slg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_85_md {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_85_6ls {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_85_5ls {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_85_sm {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_85_msm {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_85_xs {
    margin-bottom: 85px !important;
  }
}
.mt_86 {
  margin-top: 86px !important;
}

@media screen and (max-width: 1980px) {
  .mt_86_xlg {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_86_lg {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_86_mlg {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_86_slg {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_86_md {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_86_6ls {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_86_5ls {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_86_sm {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_86_msm {
    margin-top: 86px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_86_xs {
    margin-top: 86px !important;
  }
}
.mb_86 {
  margin-bottom: 86px !important;
}

@media screen and (max-width: 1980px) {
  .mb_86_xlg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_86_lg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_86_mlg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_86_slg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_86_md {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_86_6ls {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_86_5ls {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_86_sm {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_86_msm {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_86_xs {
    margin-bottom: 86px !important;
  }
}
.mt_87 {
  margin-top: 87px !important;
}

@media screen and (max-width: 1980px) {
  .mt_87_xlg {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_87_lg {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_87_mlg {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_87_slg {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_87_md {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_87_6ls {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_87_5ls {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_87_sm {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_87_msm {
    margin-top: 87px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_87_xs {
    margin-top: 87px !important;
  }
}
.mb_87 {
  margin-bottom: 87px !important;
}

@media screen and (max-width: 1980px) {
  .mb_87_xlg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_87_lg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_87_mlg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_87_slg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_87_md {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_87_6ls {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_87_5ls {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_87_sm {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_87_msm {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_87_xs {
    margin-bottom: 87px !important;
  }
}
.mt_88 {
  margin-top: 88px !important;
}

@media screen and (max-width: 1980px) {
  .mt_88_xlg {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_88_lg {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_88_mlg {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_88_slg {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_88_md {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_88_6ls {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_88_5ls {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_88_sm {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_88_msm {
    margin-top: 88px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_88_xs {
    margin-top: 88px !important;
  }
}
.mb_88 {
  margin-bottom: 88px !important;
}

@media screen and (max-width: 1980px) {
  .mb_88_xlg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_88_lg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_88_mlg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_88_slg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_88_md {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_88_6ls {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_88_5ls {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_88_sm {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_88_msm {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_88_xs {
    margin-bottom: 88px !important;
  }
}
.mt_89 {
  margin-top: 89px !important;
}

@media screen and (max-width: 1980px) {
  .mt_89_xlg {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_89_lg {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_89_mlg {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_89_slg {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_89_md {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_89_6ls {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_89_5ls {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_89_sm {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_89_msm {
    margin-top: 89px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_89_xs {
    margin-top: 89px !important;
  }
}
.mb_89 {
  margin-bottom: 89px !important;
}

@media screen and (max-width: 1980px) {
  .mb_89_xlg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_89_lg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_89_mlg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_89_slg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_89_md {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_89_6ls {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_89_5ls {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_89_sm {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_89_msm {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_89_xs {
    margin-bottom: 89px !important;
  }
}
.mt_90 {
  margin-top: 90px !important;
}

@media screen and (max-width: 1980px) {
  .mt_90_xlg {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_90_lg {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_90_mlg {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_90_slg {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_90_md {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_90_6ls {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_90_5ls {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_90_sm {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_90_msm {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_90_xs {
    margin-top: 90px !important;
  }
}
.mb_90 {
  margin-bottom: 90px !important;
}

@media screen and (max-width: 1980px) {
  .mb_90_xlg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_90_lg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_90_mlg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_90_slg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_90_md {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_90_6ls {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_90_5ls {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_90_sm {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_90_msm {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_90_xs {
    margin-bottom: 90px !important;
  }
}
.mt_91 {
  margin-top: 91px !important;
}

@media screen and (max-width: 1980px) {
  .mt_91_xlg {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_91_lg {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_91_mlg {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_91_slg {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_91_md {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_91_6ls {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_91_5ls {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_91_sm {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_91_msm {
    margin-top: 91px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_91_xs {
    margin-top: 91px !important;
  }
}
.mb_91 {
  margin-bottom: 91px !important;
}

@media screen and (max-width: 1980px) {
  .mb_91_xlg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_91_lg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_91_mlg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_91_slg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_91_md {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_91_6ls {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_91_5ls {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_91_sm {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_91_msm {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_91_xs {
    margin-bottom: 91px !important;
  }
}
.mt_92 {
  margin-top: 92px !important;
}

@media screen and (max-width: 1980px) {
  .mt_92_xlg {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_92_lg {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_92_mlg {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_92_slg {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_92_md {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_92_6ls {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_92_5ls {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_92_sm {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_92_msm {
    margin-top: 92px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_92_xs {
    margin-top: 92px !important;
  }
}
.mb_92 {
  margin-bottom: 92px !important;
}

@media screen and (max-width: 1980px) {
  .mb_92_xlg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_92_lg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_92_mlg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_92_slg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_92_md {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_92_6ls {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_92_5ls {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_92_sm {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_92_msm {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_92_xs {
    margin-bottom: 92px !important;
  }
}
.mt_93 {
  margin-top: 93px !important;
}

@media screen and (max-width: 1980px) {
  .mt_93_xlg {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_93_lg {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_93_mlg {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_93_slg {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_93_md {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_93_6ls {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_93_5ls {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_93_sm {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_93_msm {
    margin-top: 93px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_93_xs {
    margin-top: 93px !important;
  }
}
.mb_93 {
  margin-bottom: 93px !important;
}

@media screen and (max-width: 1980px) {
  .mb_93_xlg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_93_lg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_93_mlg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_93_slg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_93_md {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_93_6ls {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_93_5ls {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_93_sm {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_93_msm {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_93_xs {
    margin-bottom: 93px !important;
  }
}
.mt_94 {
  margin-top: 94px !important;
}

@media screen and (max-width: 1980px) {
  .mt_94_xlg {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_94_lg {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_94_mlg {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_94_slg {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_94_md {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_94_6ls {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_94_5ls {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_94_sm {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_94_msm {
    margin-top: 94px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_94_xs {
    margin-top: 94px !important;
  }
}
.mb_94 {
  margin-bottom: 94px !important;
}

@media screen and (max-width: 1980px) {
  .mb_94_xlg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_94_lg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_94_mlg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_94_slg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_94_md {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_94_6ls {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_94_5ls {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_94_sm {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_94_msm {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_94_xs {
    margin-bottom: 94px !important;
  }
}
.mt_95 {
  margin-top: 95px !important;
}

@media screen and (max-width: 1980px) {
  .mt_95_xlg {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_95_lg {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_95_mlg {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_95_slg {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_95_md {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_95_6ls {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_95_5ls {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_95_sm {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_95_msm {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_95_xs {
    margin-top: 95px !important;
  }
}
.mb_95 {
  margin-bottom: 95px !important;
}

@media screen and (max-width: 1980px) {
  .mb_95_xlg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_95_lg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_95_mlg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_95_slg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_95_md {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_95_6ls {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_95_5ls {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_95_sm {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_95_msm {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_95_xs {
    margin-bottom: 95px !important;
  }
}
.mt_96 {
  margin-top: 96px !important;
}

@media screen and (max-width: 1980px) {
  .mt_96_xlg {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_96_lg {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_96_mlg {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_96_slg {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_96_md {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_96_6ls {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_96_5ls {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_96_sm {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_96_msm {
    margin-top: 96px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_96_xs {
    margin-top: 96px !important;
  }
}
.mb_96 {
  margin-bottom: 96px !important;
}

@media screen and (max-width: 1980px) {
  .mb_96_xlg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_96_lg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_96_mlg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_96_slg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_96_md {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_96_6ls {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_96_5ls {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_96_sm {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_96_msm {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_96_xs {
    margin-bottom: 96px !important;
  }
}
.mt_97 {
  margin-top: 97px !important;
}

@media screen and (max-width: 1980px) {
  .mt_97_xlg {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_97_lg {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_97_mlg {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_97_slg {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_97_md {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_97_6ls {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_97_5ls {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_97_sm {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_97_msm {
    margin-top: 97px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_97_xs {
    margin-top: 97px !important;
  }
}
.mb_97 {
  margin-bottom: 97px !important;
}

@media screen and (max-width: 1980px) {
  .mb_97_xlg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_97_lg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_97_mlg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_97_slg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_97_md {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_97_6ls {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_97_5ls {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_97_sm {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_97_msm {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_97_xs {
    margin-bottom: 97px !important;
  }
}
.mt_98 {
  margin-top: 98px !important;
}

@media screen and (max-width: 1980px) {
  .mt_98_xlg {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_98_lg {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_98_mlg {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_98_slg {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_98_md {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_98_6ls {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_98_5ls {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_98_sm {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_98_msm {
    margin-top: 98px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_98_xs {
    margin-top: 98px !important;
  }
}
.mb_98 {
  margin-bottom: 98px !important;
}

@media screen and (max-width: 1980px) {
  .mb_98_xlg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_98_lg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_98_mlg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_98_slg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_98_md {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_98_6ls {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_98_5ls {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_98_sm {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_98_msm {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_98_xs {
    margin-bottom: 98px !important;
  }
}
.mt_99 {
  margin-top: 99px !important;
}

@media screen and (max-width: 1980px) {
  .mt_99_xlg {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_99_lg {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_99_mlg {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_99_slg {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_99_md {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_99_6ls {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_99_5ls {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_99_sm {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_99_msm {
    margin-top: 99px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_99_xs {
    margin-top: 99px !important;
  }
}
.mb_99 {
  margin-bottom: 99px !important;
}

@media screen and (max-width: 1980px) {
  .mb_99_xlg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_99_lg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_99_mlg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_99_slg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_99_md {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_99_6ls {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_99_5ls {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_99_sm {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_99_msm {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_99_xs {
    margin-bottom: 99px !important;
  }
}
.mt_100 {
  margin-top: 100px !important;
}

@media screen and (max-width: 1980px) {
  .mt_100_xlg {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mt_100_lg {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mt_100_mlg {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 960px) {
  .mt_100_slg {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 599px) {
  .mt_100_md {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 667px) {
  .mt_100_6ls {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .mt_100_5ls {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 414px) {
  .mt_100_sm {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 375px) {
  .mt_100_msm {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 320px) {
  .mt_100_xs {
    margin-top: 100px !important;
  }
}
.mb_100 {
  margin-bottom: 100px !important;
}

@media screen and (max-width: 1980px) {
  .mb_100_xlg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_100_lg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_100_mlg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 960px) {
  .mb_100_slg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 599px) {
  .mb_100_md {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_100_6ls {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_100_5ls {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 414px) {
  .mb_100_sm {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_100_msm {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_100_xs {
    margin-bottom: 100px !important;
  }
}
.mt_auto {
  margin-top: auto;
}

@media screen and (max-width: 1980px) {
  .mt_auto--xlg {
    margin-top: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mt_auto--lg {
    margin-top: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mt_auto--mlg {
    margin-top: auto;
  }
}
@media screen and (max-width: 960px) {
  .mt_auto--slg {
    margin-top: auto;
  }
}
@media screen and (max-width: 599px) {
  .mt_auto--md {
    margin-top: auto;
  }
}
@media screen and (max-width: 667px) {
  .mt_auto--6ls {
    margin-top: auto;
  }
}
@media screen and (max-width: 568px) {
  .mt_auto--5ls {
    margin-top: auto;
  }
}
@media screen and (max-width: 414px) {
  .mt_auto--sm {
    margin-top: auto;
  }
}
@media screen and (max-width: 375px) {
  .mt_auto--msm {
    margin-top: auto;
  }
}
@media screen and (max-width: 320px) {
  .mt_auto--xs {
    margin-top: auto;
  }
}
.mb_auto {
  margin-bottom: auto;
}

@media screen and (max-width: 1980px) {
  .mb_auto--xlg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mb_auto--lg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mb_auto--mlg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 960px) {
  .mb_auto--slg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 599px) {
  .mb_auto--md {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 667px) {
  .mb_auto--6ls {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 568px) {
  .mb_auto--5ls {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 414px) {
  .mb_auto--sm {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 375px) {
  .mb_auto--msm {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 320px) {
  .mb_auto--xs {
    margin-bottom: auto;
  }
}
.ml_auto {
  margin-left: auto;
}

@media screen and (max-width: 1980px) {
  .ml_auto--xlg {
    margin-left: auto;
  }
}
@media screen and (max-width: 1366px) {
  .ml_auto--lg {
    margin-left: auto;
  }
}
@media screen and (max-width: 1024px) {
  .ml_auto--mlg {
    margin-left: auto;
  }
}
@media screen and (max-width: 960px) {
  .ml_auto--slg {
    margin-left: auto;
  }
}
@media screen and (max-width: 599px) {
  .ml_auto--md {
    margin-left: auto;
  }
}
@media screen and (max-width: 667px) {
  .ml_auto--6ls {
    margin-left: auto;
  }
}
@media screen and (max-width: 568px) {
  .ml_auto--5ls {
    margin-left: auto;
  }
}
@media screen and (max-width: 414px) {
  .ml_auto--sm {
    margin-left: auto;
  }
}
@media screen and (max-width: 375px) {
  .ml_auto--msm {
    margin-left: auto;
  }
}
@media screen and (max-width: 320px) {
  .ml_auto--xs {
    margin-left: auto;
  }
}
.mr_auto {
  margin-right: auto;
}

@media screen and (max-width: 1980px) {
  .mr_auto--xlg {
    margin-right: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mr_auto--lg {
    margin-right: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mr_auto--mlg {
    margin-right: auto;
  }
}
@media screen and (max-width: 960px) {
  .mr_auto--slg {
    margin-right: auto;
  }
}
@media screen and (max-width: 599px) {
  .mr_auto--md {
    margin-right: auto;
  }
}
@media screen and (max-width: 667px) {
  .mr_auto--6ls {
    margin-right: auto;
  }
}
@media screen and (max-width: 568px) {
  .mr_auto--5ls {
    margin-right: auto;
  }
}
@media screen and (max-width: 414px) {
  .mr_auto--sm {
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .mr_auto--msm {
    margin-right: auto;
  }
}
@media screen and (max-width: 320px) {
  .mr_auto--xs {
    margin-right: auto;
  }
}
.ms_auto {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1980px) {
  .ms_auto--xlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1366px) {
  .ms_auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1024px) {
  .ms_auto--mlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 960px) {
  .ms_auto--slg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 599px) {
  .ms_auto--md {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 667px) {
  .ms_auto--6ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 568px) {
  .ms_auto--5ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 414px) {
  .ms_auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .ms_auto--msm {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 320px) {
  .ms_auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
}
.text_bold {
  font-weight: bold;
}

.text_right {
  text-align: right;
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.textColor_primary {
  color: #0082aa !important;
}

.textColor_success {
  color: #137E00 !important;
}

.textColor_info {
  color: #4A90B9 !important;
}

.textColor_warning {
  color: #FF7F00 !important;
}

.textColor_error {
  color: #d9534f !important;
}

.text_bigger {
  font-size: 1.6em;
  vertical-align: -10%;
}