@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// README
// ============================================================
//
// HTML SAMPLE
//
//  <div id="offcanvasTrigger" class="btn">ボタン</div>
//    <ul id="offcanvas" class="offcanvasMenu">
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//      <li>メニュー</li>
//    </ul>
//  </div>
//
// # 備考
// ヘッダーメニューボタン（ハンバーガーメニュー）に対してid="menuTrigger"を付与
// グローバルナビゲーション要素に対して id="offcanvas" を付与する必要が条件
// 背景レイヤー（offcanvasBg）はJS側で自動生成されます
// 開閉アニメーションについてはCSSで全て設定しているので、アニメーションを変更する際はCSSで調整すること
//
// ============================================================

// Config
//
// 以下で設定する$BLAKEPOINT_HEADER_SP_MODEがoffcanvas処理が発火するブレイクポイントとなる
// 以下のブレイクポイントを変更する場合は /src/assets/js/function/_app.js の以下のコードの値を$BLAKEPOINT_HEADER_SP_MODEと同じにする必要がある
//
// let BLAKEPOINT_HEADER_SP_MODE = 1024;
//
    $BLAKEPOINT_HEADER_SP_MODE : var.$screen-slg;
//
// -------------------

.offcanvasTrigger{
  z-index: var.$zindex_offcanvas_trigger;
  position: fixed;
}

// -------------------
// 構造
// -------------------

.js_isOffcanvasActive{
  max-width:100%;
}


// 見た目
// -------------------

.offcanvasMenu{
  display: none;
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE){
    width: calc(100% - 60px);
    background: #fff;
    overflow-x: hidden;
    @include mix.transitionDelay(.3s);
    display: block;
  }
}

// 構造
// -------------------

.offcanvasMenu{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    z-index: var.$zindex_offcanvas_menu;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //min-height: 100vh;
    transform: translate(-100%, 0);
    padding: var.$header_H_SP 0;
  }
}

.js_isOffcanvasActive .offcanvasMenu{
  transform : translate(0%,0);
}

// 見た目
// -------------------

.offcanvasBg{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    background: var.$background_layer_color;
    @include mix.transitionDelay(.4s);
  }
}

// 構造
// -------------------

.offcanvasBg{
  @include mix.max-media($BLAKEPOINT_HEADER_SP_MODE) {
    z-index: var.$zindex_offcanvas_bg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    transform: translate(-100%, 0);
  }
}

.js_isOffcanvasActive .offcanvasBg{
  transform : translate(0%,0);
}

// オフキャンバスメニューボタンを押した際の文言の変化の指定
// ============================================================

.js_isOffcanvasActive .offcanvasTrigger_labelOpen{
  display: none;
}

.offcanvasTrigger_labelClose{
  display: none;
}

.js_isOffcanvasActive .offcanvasTrigger_labelClose {
  display: inline;
}
