@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// display visible and hidden
// ------------------------------------------------------------

@each $media, $blakepoint in var.$blakepoints {
  .hidden_#{$media}{
    @media screen and (max-width: #{$blakepoint}) {
      display: none!important;
    }
  }
}

@each $media, $blakepoint in var.$blakepoints {
  .visible_#{$media}{
    display: none!important;
    @media screen and (max-width: #{$blakepoint}) {
      display: block!important;
    }
  }
}

@each $media, $blakepoint in var.$blakepoints {
  .visible_#{$media}_inline{
    display: none!important;
    @media screen and (max-width: #{$blakepoint}) {
      display: inline-block!important;
    }
  }
}

// display model
// ------------------------------------------------------------

.dis_block{
  display: block;
}

.dis_inlineBlock{
  display: inline-block;
}

.dis_flex{
  display: flex;
}

.dis_hidden{
  display: none;
}

