@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.panel01{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  background-color: rgba(255,255,255,.6);

  @include mix.max-media(var.$screen-md){
    display: block;
    background-color: transparent;
  }

}

.panel01_img{
  @include mix.transitionDelay();
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  //
  position: relative;
  left: -3.3%;
  width: calc(100% / 3);
  // min-height: 454px;
  transform: skew(-6deg);
  overflow: hidden;
  box-shadow: 2px 3px 5px 1px rgb(35 24 21 / 70%);

  @include mix.max-media(var.$screen-slg){
    left: 0;
  }


  @include mix.max-media(var.$screen-lmd){
    // min-height: 364px;

  }

  @include mix.max-media(var.$screen-md){
    width: 100%;
    margin-left: -16%;
    margin-right: 0;
    min-height: 224px;
    transform: skew(-10deg);
  }

  @include mix.max-media(var.$screen-sm){
    margin-left: -10%;
  }

  a{
    display: block;
    position: relative;
    width: 100%;
  }

  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform :skew(6deg) translate(-50%,-50%);
    //
    width: 150%;
    max-width: 150%;
    height: 120%;
    filter: grayscale(100%);
    object-fit: cover;
  }

  &:hover,
  .js_isHover &{
    img{
      @include mix.transitionDelay();
      filter: grayscale(0%);
    }
  }

  .panel01:nth-of-type(even) &{
    @include mix.max-media(var.$screen-md){
      margin-left: 25%;
    }

    @include mix.max-media(var.$screen-sm){
      margin-left: 15%;
    }
  }
}


.panel01ContentsGroup{
  box-sizing: border-box;
  width: calc(100% - 100% / 3);
  padding: 20px 40px;
  margin-left: auto;

  @include mix.max-media(var.$screen-md){
    width: 100%;
    background-color: rgba(255,255,255,.7);
    margin: 24px 0;
    padding: 0;
  }
}


//セクション固有
// ============================================================

.topSectionContents_panel01{

  margin-bottom: 80px;

  @include mix.max-media(var.$screen-slg){
    margin-bottom: 70px;
  }

  @include mix.max-media(var.$screen-lmd){
    margin-bottom: 60px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-bottom: 60px;
  }

  &:last-child{
    margin-bottom: 0;
  }


}

// 外国人労働者セクション固有の記述
// ============================================================

.panel01_foreignWorker_img{
  left: -5%;
  transform: skew(0);

  @include mix.max-media(var.$screen-slg){
    left: -3%;
  }

  img{
    transform :skew(0) translate(-50%,-50%);
  }
}