@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.offcanvasGroup{
  display: none;
  position: relative;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: var.$brand-accent;

  @include mix.max-media(var.$screen-slg){
    display: block;
  }
}

.offcanvasTrigger {
  @include mix.min-media(var.$screen-mlg) {
    display: none;
  }
}

.offcanvasMenu_lead{
  position: relative;
  text-decoration: none;
  font-size: 10px;

  @include mix.max-media(var.$screen-slg){
    font-size: 12px;
    margin-left: 1em;
  }
  
  &:before{
    position: absolute;
    left: -.5em;
    display: none;
    content: '';
    height: 1em;
    width: 1px;
    background-color: var.$heading-text-color;
    transform: rotate(15deg);

    @include mix.max-media(var.$screen-slg){
      display: block;
    }
  }
}



// offcanvas_footer{

.offcanvasFooter{
  display: none;
  @include mix.max-media(var.$screen-slg){
    display: block;
    width: 100%;
  }
}

.offcanvasFooter_menu{
  display: none;
  @include mix.max-media(var.$screen-slg){
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
  }
}

.offcanvasFooter_menu_item{
  display: none;
  @include mix.max-media(var.$screen-slg){
    position: relative;
    display: block;
    width: calc((100% - 1px) / 2);
    border-top: 1px solid #595757;
    border-bottom: 1px solid #595757;
  }

  &:first-child{
    @include mix.max-media(var.$screen-slg){
      border-right: 1px solid #595757;
    }
  }

  &:after{
    position: absolute;
    top: 45%;
    right: 1.4em;
    display: block;
    content: '';
    width: 5px;
    height: 5px;
    border-right: 2px solid #595757;
    border-bottom: 2px solid #595757;
    transform: rotate(-45deg) translate(-50%,-50%);
  }

  a{
    @include mix.max-media(var.$screen-slg){
      @include mix.setTextLinkColor(var.$text-color);
      @include mix.setLinkHoverFadeAnime();
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-family: var.$font-family-base;
      font-size: 15px;
      line-height: 1.1;
      padding: 21px 21px 20px;

    }
  }
}

.offcanvasFooter_btn_group{
  padding: 18px;
}

.offcanvasFooterTel_btn{
  background-color: #FFF100;
  margin-bottom: 8px;
  padding: 3px 0 2px;
}


// ============================================================
// offcanvasTriggerUnit
// ============================================================

$trigger_color: #fff;
$trigger_color_active: #fff;
$triggerW: 31px;
$triggerH: 23px;
$triggerWSP: 21px;
$triggerHSP: 15px;

.offcanvasTriggerUnit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: var.$zindex_offcanvas_trigger;
  //
  display: none;
  @include mix.max-media(var.$screen-mlg) {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.offcanvasTrigger,
.offcanvasTrigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}
.offcanvasTrigger {
  position: relative;
  width: $triggerW;
  height: $triggerH;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  // @include mix.max-media(var.$screen-sm){
  //   width: $triggerWSP;
  //   height: $triggerHSP;
  // }
}
.offcanvasTrigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $trigger_color;
}
.offcanvasTrigger span:nth-of-type(1) {
  top: 0;
}
.offcanvasTrigger span:nth-of-type(2) {
  top: $triggerW / 2 - 5.5px;

  @include mix.max-media(var.$screen-sm) {
    top: 45%;
  }
}
.offcanvasTrigger span:nth-of-type(3) {
  bottom: 0;
}

.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(1) {
  transform: translateY(11px) rotate(-45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(7px) rotate(-45deg);
  // }
}
.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  animation: active-menu05-bar02 0.8s forwards;
}
@keyframes active-menu05-bar02 {
  100% {
    height: 0;
  }
}
.js_isOffcanvasActive .offcanvasTrigger span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
  background-color: $trigger_color_active;

  // @include mix.max-media(var.$screen-sm){
  //   transform: translateY(-7px) rotate(45deg);
  // }
}

//
// -------------------

.offcanvasTrigger_label {
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  font-family: var.$font-family-base;
  color: $trigger_color;

  // @include mix.max-media(var.$screen-sm){
  //   transform: scale(0.75);
  //   margin-top: 3px;
  // }
}

.js_isOffcanvasActive .offcanvasTrigger_label {
  color: $trigger_color_active;
}


// ============================================================
//オフキャンバス時のみの固有要素
// ============================================================

//offcanvasRecruit

.offcanvasRecruit_menu{
  display: none;
  @include mix.max-media(var.$screen-slg){
    display: block;
    margin-top: 20px;
  }
}

.offcanvasRecruit_item{
    margin-right: 50px;

    @include mix.max-media(var.$screen-slg){
      position: relative;
      margin-right: 0;
    }


    &:after{
      position: absolute;
      display: none;
      content: '';
      width: 5px;
      height: 5px;
      border-right: 2px solid #595757;
      border-bottom: 2px solid #595757;
      transform: translate(-50%,-50%) rotate(-45deg);
      top: 50%;
      right: 0;

      @include mix.max-media(var.$screen-slg){
        display: block;
      }
    }

    &:last-child{
      margin-right: 0;
    }

    a{
      @include mix.transitionDelay();
      display: block;
      padding: 30px 0;
      font-family: Avenir;
      font-size: 28px;
      font-weight: 500;
      color: var.$heading-text-color;
      line-height: 1.1;
      letter-spacing: .06em;

      @include mix.max-media(var.$screen-slg){
        font-size: 20px;
        padding: 16px 0;
      }


      &:active,
      &:link,
      &:visited,
      &:focus{
        color: var.$heading-text-color;
      }
      &:hover{
        @include mix.transitionDelay();
        opacity: .7;
      }
    }

    &.is_current{
      text-decoration: underline;
      text-decoration-color: var.$heading-text-color;
      text-decoration-thickness: 1px;
      text-underline-offset: 12px;
    }
}

.offcanvasRecruit_itemPrimal{
  background-color: var.$brand-accent;

  margin-bottom: 10px;

  @include mix.max-media(var.$screen-slg){
    width: 106%;
    margin-left: -3%;
  }

  @include mix.max-media(var.$screen-md){
   width: 109%;
    margin-left: -5%;
  }

  @include mix.max-media(var.$screen-sm){
    width: 112%;
    margin-left: -6%;
  }

  &:after{
    right: 1.5em;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }

  a{
    @include mix.max-media(var.$screen-slg){
      padding: 16px 18px;
      color: var.$text-color-white;

      &:active,
      &:link,
      &:visited,
      &:focus,
      &:hover{
        color: var.$text-color-white;
      }
    }

    .offcanvasMenu_lead:before{
      background-color: #fff;
    }

  }
}

