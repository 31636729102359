@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.header{
  position: fixed;
  z-index: var.$zindex-header;
  top: 0;
  left: 0;
  right: 0;
  //
  width: 100%;
  //height: var.$header_H_PC;

  @include mix.max-media(var.$screen-slg){
    height: var.$header_H_SP;
    z-index: var.$zindex_offcanvas_trigger;
  }
}

.header_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  background-color: rgba(255,255,255,.7);
  padding-left: function.px2per(64,1532);
}

.header_logo{
  @include mix.setLinkHoverFadeAnime();
  background-image: url("../img/top/header_logo.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16%;
  max-width: 240px;

  @include mix.max-media(var.$screen-md){
    width: 26%;
  }
  
  @include mix.max-media(var.$screen-sm){
    width: 30%;
  }

  a{
    display: block;
    width: 100%;
    padding-top: var.$header-primary-menu_H;

    @include mix.max-media(var.$screen-slg){
      padding-top: 54px;
    }
  }
  
}

.gnav{
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
//
  width: 84%;

  @include mix.max-media(var.$screen-slg){
    width: auto;
  }
}

.gnav_list{
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
  width: 100%;

  @include mix.max-media(var.$screen-slg){
    display: block;
    width: auto;
    padding: 20px;
  }
}

.gnav_listItem{
  margin-right: 50px;
  text-align: center;
  @include mix.max-media(var.$screen-mlg){
    margin-right: 36px;
  }

  @include mix.max-media(var.$screen-slg){
    position: relative;
    margin-right: 0;
    text-align: left;
  }


  &:after{
    position: absolute;
    display: none;
    content: '';
    width: 5px;
    height: 5px;
    border-right: 2px solid #595757;
    border-bottom: 2px solid #595757;
    transform: translate(-50%,-50%) rotate(-45deg);
    top: 50%;
    right: 0;

    @include mix.max-media(var.$screen-slg){
      display: block;
    }
  }

  &:first-child{
    a{
      padding: 23px 0 36px;

      @include mix.max-media(var.$screen-slg){
        padding: 16px 0;
      }
    }
  }

  &:last-child{
    margin-right: 0;
  }

  a{
    @include mix.transitionDelay();
    display: block;
    padding: 18px 0;
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
    color: var.$heading-text-color;
    line-height: 1.1;
    letter-spacing: .06em;

    @include mix.max-media(var.$screen-slg){
      padding: 16px 0;
    }


    &:active,
    &:link,
    &:visited,
    &:focus{
      color: var.$heading-text-color;
    }
    &:hover{
      @include mix.transitionDelay();
      opacity: .7;
    }
  }
  span{
    margin-top: 2px;
    @include mix.max-media(var.$screen-slg){
      margin-top: auto;
    }
  }

  &.is_current{
    text-decoration: underline;
    text-decoration-color: var.$heading-text-color;
    text-decoration-thickness: 1px;
    text-underline-offset: 12px;
  }
}