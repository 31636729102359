@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


//ヘッダー左端採用情報dropDownMenu
// ============================================================
.recruit_dropDownMenuTrigger{
}

.recruit_dropDownMenu{
  display: none;
  background-color: rgba(255, 255, 255, 0.7);
}

.recruit_dropDownMenuTrigger:hover{
  .recruit_dropDownMenu{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
  }
}

.recruit_dropDownItem{
  border-top: 1px dotted #4a4a4a;


  a{
    @include mix.setTextLinkColor(var.$heading-text-color);
    letter-spacing: 0;
    line-height: 1.1;
    font-size: 18px;
  }

  &:first-child{
    border-top: none;
  }
}

