@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// text
// ------------------------------------------------------------

.text_bold{
  font-weight: bold;
}

// text align
// ------------------------------------------------------------

.text_right{
  text-align: right;
}
.text_left{
  text-align: left;
}
.text_center{
  text-align: center;
}

// text color
// ------------------------------------------------------------

.textColor_primary{
  color: var.$brand-primary !important;
}

.textColor_success{
  color: var.$brand-success !important;
}

.textColor_info{
  color: var.$brand-info !important;
}

.textColor_warning{
  color: var.$brand-warning !important;
}

.textColor_error{
  color: var.$brand-error !important;
}

//bigger

.text_bigger{
  font-size: 1.6em;
  vertical-align: -10%;
}