@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//  README
// ------------------------------------------------------------
//  ユーティリティ用のマージンクラスを使用する場合「$utilityMarginClass」を「true」にし
//  必要な各マージンクラスを「marginTop/marginBottom/...」をtrueにする
//  $endValueはpxの最大値を表す
// ------------------------------------------------------------

$utilityMarginClass: true !default;
$marginTop: true !default;
$marginBottom: true !default;
$marginLeft: false !default;
$marginRight: false !default;
$endValue: 100 !default;

// margin class
// ------------------------------------------------------------

@if $utilityMarginClass {
  @for $i from 0 through $endValue {
    @if $marginTop {
      .mt_#{$i} {
        margin-top: $i + px !important;
      }
      @include mix.max-media(var.$screen-xlg) {
        .mt_#{$i}_xlg {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .mt_#{$i}_lg {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .mt_#{$i}_mlg {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .mt_#{$i}_slg {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .mt_#{$i}_md {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .mt_#{$i}_6ls {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .mt_#{$i}_5ls {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .mt_#{$i}_sm {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .mt_#{$i}_msm {
          margin-top: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .mt_#{$i}_xs {
          margin-top: $i + px !important;
        }
      }
    }
    @if $marginBottom {
      .mb_#{$i} {
        margin-bottom: $i + px !important;
      }
      @include mix.max-media(var.$screen-xlg) {
        .mb_#{$i}_xlg {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .mb_#{$i}_lg {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .mb_#{$i}_mlg {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .mb_#{$i}_slg {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .mb_#{$i}_md {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .mb_#{$i}_6ls {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .mb_#{$i}_5ls {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .mb_#{$i}_sm {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .mb_#{$i}_msm {
          margin-bottom: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .mb_#{$i}_xs {
          margin-bottom: $i + px !important;
        }
      }
    }
    @if $marginLeft {
      .ml_#{$i} {
        margin-left: $i + px !important;
      }
      @include mix.max-media(var.$screen-xlg) {
        .ml_#{$i}_xlg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .ml_#{$i}_lg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .ml_#{$i}_mlg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .ml_#{$i}_xlg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .ml_#{$i}_md {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .ml_#{$i}_6ls {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .ml_#{$i}_5ls {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .ml_#{$i}_sm {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .ml_#{$i}_msm {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .ml_#{$i}_xs {
          margin-left: $i + px !important;
        }
      }
    }
    @if $marginRight {
      .mr_#{$i} {
        margin-right: $i + px !important;
      }
      @include mix.max-media(var.$screen-xlg) {
        .mr_#{$i}_xlg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-lg) {
        .mr_#{$i}_lg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-mlg) {
        .mr_#{$i}_mlg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-slg) {
        .mr_#{$i}_slg {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-md) {
        .mr_#{$i}_md {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-6ls) {
        .mr_#{$i}_6ls {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-5ls) {
        .mr_#{$i}_5ls {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-sm) {
        .mr_#{$i}_sm {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-msm) {
        .mr_#{$i}_msm {
          margin-left: $i + px !important;
        }
      }
      @include mix.max-media(var.$screen-xs) {
        .mr_#{$i}_xs {
          margin-left: $i + px !important;
        }
      }
    }
  }
}

// margin auto class
// ------------------------------------------------------------

// margin-top : auto
// -------------------
.mt_auto {
  margin-top: auto;
}
@include mix.max-media(var.$screen-xlg) {
  .mt_auto--xlg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-lg) {
  .mt_auto--lg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-mlg) {
  .mt_auto--mlg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-slg) {
  .mt_auto--slg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-md) {
  .mt_auto--md {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-6ls) {
  .mt_auto--6ls {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-5ls) {
  .mt_auto--5ls {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-sm) {
  .mt_auto--sm {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-msm) {
  .mt_auto--msm {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen-xs) {
  .mt_auto--xs {
    margin-top: auto;
  }
}

// margin-bottom : auto
// -------------------
.mb_auto {
  margin-bottom: auto;
}
@include mix.max-media(var.$screen-xlg) {
  .mb_auto--xlg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-lg) {
  .mb_auto--lg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-mlg) {
  .mb_auto--mlg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-slg) {
  .mb_auto--slg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-md) {
  .mb_auto--md {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-6ls) {
  .mb_auto--6ls {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-5ls) {
  .mb_auto--5ls {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-sm) {
  .mb_auto--sm {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-msm) {
  .mb_auto--msm {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen-xs) {
  .mb_auto--xs {
    margin-bottom: auto;
  }
}

// margin-left : auto
// -------------------
.ml_auto {
  margin-left: auto;
}
@include mix.max-media(var.$screen-xlg) {
  .ml_auto--xlg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-lg) {
  .ml_auto--lg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-mlg) {
  .ml_auto--mlg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-slg) {
  .ml_auto--slg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-md) {
  .ml_auto--md {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-6ls) {
  .ml_auto--6ls {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-5ls) {
  .ml_auto--5ls {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-sm) {
  .ml_auto--sm {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-msm) {
  .ml_auto--msm {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen-xs) {
  .ml_auto--xs {
    margin-left: auto;
  }
}

// margin-right : auto
// -------------------
.mr_auto {
  margin-right: auto;
}
@include mix.max-media(var.$screen-xlg) {
  .mr_auto--xlg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-lg) {
  .mr_auto--lg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-mlg) {
  .mr_auto--mlg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-slg) {
  .mr_auto--slg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-md) {
  .mr_auto--md {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-6ls) {
  .mr_auto--6ls {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-5ls) {
  .mr_auto--5ls {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-sm) {
  .mr_auto--sm {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-msm) {
  .mr_auto--msm {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-xs) {
  .mr_auto--xs {
    margin-right: auto;
  }
}

// margin：0 auto;
// -------------------
.ms_auto {
  margin-left: auto;
  margin-right: auto;
}
@include mix.max-media(var.$screen-xlg) {
  .ms_auto--xlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-lg) {
  .ms_auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-mlg) {
  .ms_auto--mlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-slg) {
  .ms_auto--slg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-md) {
  .ms_auto--md {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-6ls) {
  .ms_auto--6ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-5ls) {
  .ms_auto--5ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-sm) {
  .ms_auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-msm) {
  .ms_auto--msm {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen-xs) {
  .ms_auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
}