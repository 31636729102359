@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


//btn_group関連
// ============================================================
.btn_group_center{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.btn_group_right{
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  align-items: center;
}

btn_group_left{
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
}
//各セクション固有btn_group

.topSectionRecruitmenty_btnGroup{
  @include mix.max-media(var.$screen-lmd){
    display: block;
  }
}

.top_panel01_btnGroup{
  @include mix.max-media(var.$screen-md){
    width: 100%;
  }
}


//btn01
// ============================================================

.btn01{
  @include mix.transitionDelay();
  background-color:  #595757;
  color: var.$text-color-white;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 2px 0 1px;
  min-width: 244px;
  text-align: center;
  font-family: var.$font-family-base;
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  letter-spacing: .1em;

  @include mix.max-media(var.$screen-md){
    min-width: auto;
    width: 100%;
    font-size: 14px;
    padding: 10px 0 9px;
  }

  &:active,
  &:link,
  &:visited,
  &:focus,{
    color: var.$text-color-white;
  }

  &:hover{
    border: 1px solid #595757;
    background-color:  #FFFFFF;
    color: #000000;

  }

}

//btn02
// ============================================================

.btn02{
  @include mix.transitionDelay();
  display: block;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 20px 0 18px;
  line-height: var.$line-height-base;
  font-family: var.$font-family-base;
  font-size: 20px;
  color: var.$brand-main;

  @include mix.max-media(var.$screen-slg){
    font-size: 16px;
  }

  &:active,
  &:link,
  &:visited,
  &:focus{
    color: var.$brand-main;
  }
  &:hover{
    background-color: var.$brand-main;
    color: var.$text-color-white;
  }
}

.topSectionRecruitmenty_btn02{
  margin-bottom: 8px;

  &:last-child{
    margin-bottom: 0;
  }
}

.topSectionRecruitmenty_btn02{
  @include mix.max-media(var.$screen-lmd){
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: auto;

    &:last-child{
      margin-right: 0;
    }
  }
}

//btn03
// ============================================================

.btn03{
  @include mix.transitionDelay();
  display: block;
  width: 100%;
  padding: 12px 0 10px;
  background-color: #FFFFFF;
  text-align: center;
  font-family: var.$font-family-base;
  font-size: 20px;
  color: #595757;

  @include mix.max-media(var.$screen-slg){
    font-size: 15px;
  }

  &:active,
  &:link,
  &:visited,
  &:focus{
    color: #595757;
  }
  &:hover{
    @include mix.transitionDelay();
    background-color: #FFF100;
  }
}

.topSectionRecruitmenty_telBtn{
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 8px;

  @include mix.max-media(var.$screen-md){
    line-height: 1.1;
    padding: 6px 0 5px;
  }
}

.topSectionRecruitmenty_contactBtn{
  position: relative;
  z-index: 1;
  padding: 18px 0 16px;

  @include mix.max-media(var.$screen-md){
    padding: 9px 0 8px;
  }
}

.btn04{
  @include mix.transitionDelay();
  display: block;
  width: 100%;
  padding: 12px 0 10px;
  border: 1px solid var.$brand-accent;
  background-color: #FFFFFF;
  text-align: center;
  font-family: var.$font-family-base;
  font-size: 20px;
  color: var.$brand-accent;

  @include mix.max-media(var.$screen-slg){
    font-size: 15px;
  }

  &:active,
  &:link,
  &:visited,
  &:focus{
    color: var.$brand-accent;
  }
  &:hover{
    @include mix.transitionDelay();
    background-color: var.$brand-accent;
    border: 1px solid transparent;
    color: var.$text-color-white
  }
}

//特殊ボタン
// ============================================================

//recruit_btn  ヘッダー左端のボタン

.recruit_btn{
  position: relative;
  width: 20%;
  max-width: 240px;
  background-color: var.$brand-accent;


  a{
    @include mix.transitionDelay();
    display: block;
    width: 100%;
    padding: 32px 0;
    font-size: 20px;
    font-family: var.$font-family-base;
    text-decoration-color: var.$text-color-white;
    font-weight: normal;
    text-align: center;
    line-height: 1.1;
    letter-spacing: .3em;

    &:active,
    &:link,
    &:visited,
    &:focus{
      color: #FFFFFF;
    }

    &:hover{
      @include mix.transitionDelay();
      opacity: .7;

    }
  }
}

