@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// wrapper
// ============================================================

.wrapper{
  margin-top: var.$header_H_PC;

  @include mix.max-media(var.$screen-sm){
    margin-top: var.$header_H_SP;
  }

  .home &{
    margin-top: 0;
  }
}

// container
// ============================================================

$container_innerW : 1204px;

.container{
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @include mix.max-media(var.$screen-md){
    overflow: hidden;
  }
}

.container_inner{
  box-sizing: border-box;
  max-width: $container_innerW;
  width: function.px2per(1204,1532);
  margin: 0 auto;

  @include mix.max-media(var.$screen-mlg){
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

//section固有inner
// ============================================================

.topSectionCompany_container{
  position: relative;
  background-image: url("../img/top/company_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;


  &:after{
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: '';
    display: block;
    background: rgba(255 ,255 ,255 ,.5);
  }
}

.topSectionCompany_inner{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  //
  padding-top: 30px;
  padding-bottom: 40px;

  @include mix.max-media(var.$screen-lmd){
    display: block;

  }
}

.topSectionRecruitmenty_container{
  position: relative;
  overflow: hidden;
  background-color: var.$brand-accent;

  &:before,
  &:after{
    position: absolute;
    z-index: 0;
    content: '';
    display: block;
    width: 160px;
    height: 500px;
    background: var.$brand-main;
    transform: rotate(45deg);
  }

  &:before{
    top: -170px;
    left: 0;
    @include mix.max-media(var.$screen-md){
      top: -320px;
    }
  }

  &:after{
    bottom: -220px;
    right: 0;

    @include mix.max-media(var.$screen-md){
      bottom: -320px;
    }
  }
}

.topSectionRecruitmenty_inner {
  padding-top: 36px;
  padding-bottom: 36px;
}
