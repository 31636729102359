@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ストライプ アニメ関係
// ============================================================

.topStripeContainer{
  background-repeat: repeat;
  background-image: url('../img/top/bg_pc.webp');
  background-position: center top;
  background-size: 100%;
  padding: 80px 0;

  @include mix.max-media(var.$screen-slg){
    padding: 70px 0;
  }

  @include mix.max-media(var.$screen-lmd){
    padding: 60px 0;
    background-image: url('../img/top/bg_sp.webp');
  }


}

//ニュース一覧
// ============================================================

.topSectionNews_container{
  margin-bottom: 80px;

  @include mix.max-media(var.$screen-slg){
    margin-bottom: 70px;
  }

  @include mix.max-media(var.$screen-lmd){
    margin-bottom: 60px;
  }

  @include mix.max-media(var.$screen-sm){
    margin-bottom: 60px;
  }

}





//topSectionCompany
// ============================================================

.topSectionCompany_contentsUnit{
  position: relative;
  z-index: 1;
  width: calc(100% - (244px + 120px + 40px));
  margin-right: 120px;

  @include mix.max-media(var.$screen-slg){
    margin-right: 60px;
    width: calc(100% - (244px + 60px +  40px));
  }

  @include mix.max-media(var.$screen-lmd){
    width: 100%;
    margin-right: 0;
  }
}

.topSectionCompany_btnGroup{
  position: relative;
  z-index: 1;
  align-items: end;
  justify-content: end;
  min-width: 244px;
  max-width: 244px;
  margin-right: 40px;
  margin-left: auto;

  @include mix.max-media(var.$screen-slg){

  }


  @include mix.max-media(var.$screen-lmd){
    max-width: none;
    min-width: auto;
    width: 100%;
    justify-content: center;
  }
}

//topSectionRecruitmenty
// ============================================================

.topSectionRecruitmenty_contentsGroup{
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: start;
//
  margin-bottom: 36px;

  @include mix.max-media(var.$screen-lmd){
    display: block;
  }
}

.topSectionRecruitmenty_primalyUnit{
  width: calc(100% - (36px + 320px));
  margin-right: 36px;

  @include mix.max-media(var.$screen-mlg){
    width: calc(100% - (24px + 245px));
    margin-right: 24px;
  }

  @include mix.max-media(var.$screen-lmd){
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.topSectionRecruitmenty_secondalyUnit{
  min-width: 320px;
  max-width: 320px;

  @include mix.max-media(var.$screen-mlg){
    max-width: 245px;
    min-width: 245px;
  }

  @include mix.max-media(var.$screen-lmd){
    max-width: 260px;
    min-width: 260px;
    margin: 0 auto;
  }


  @include mix.max-media(var.$screen-md){
    max-width: 236px;
    min-width: 236px;

  }
}


// メンテナンス時にトップに表示させる画像
// ============================================================

.mentenance_img{
  width: 100%;
  margin: 0 auto;
  img{
    width: 100%;
    max-width: 100%;
  }
}