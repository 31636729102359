@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

$circle_W_pc : function.px2vw(102,766);
$circle_H_pc : function.px2vw(102,766);
$circle_move_offset_pc : $circle_W_pc / 2 * -1;

$circle_W_sp : function.px2vw(100,390);
$circle_H_sp : function.px2vw(100,390);
$circle_move_offset_sp : $circle_W_sp / 2 * -1;

.scrollDownUnit{
  position: relative;
  width: $circle_W_pc;
  height: $circle_H_pc;

  @include mix.max-media(var.$screen-md){
    width: $circle_W_sp;
    height: $circle_H_sp;
  }

}

.scrollDownUnit_circle{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: $circle_W_pc;
  height: $circle_H_pc;
  border-radius: 50%;
  border: solid 1px var.$brand-main;

  @include mix.max-media(var.$screen-md){
    width: $circle_W_sp;
    height: $circle_H_sp;
  }

}

.scrollDownUnit_item{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  //
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var.$brand-main;
  animation: circle-move-anim 4s linear infinite;


}

// サークル内部テキスト
// ------------------------------------------------------------

.scrollDownUnit_text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform : translate(-50%,-50%);
  line-height: 1.4;
  //
  font-family: Avenir;
  font-size: 1.5vw;
  color: var.$brand-main;
  letter-spacing: .26em;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.7);
  text-align: center;

  @include mix.max-media(var.$screen-md){
    font-size: 3vw;
  }
}

// ============================================================
// アニメーション設定
// ============================================================

@keyframes circle-move-anim {

  0% {
    transform: rotate(0deg) translateX(calc(#{$circle_move_offset_pc})) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translateX(calc(#{$circle_move_offset_pc})) rotate(-360deg);
  }

}

@include mix.max-media(var.$screen-md){
  @keyframes circle-move-anim {

    0% {
      transform: rotate(0deg) translateX(calc(#{$circle_move_offset_sp})) rotate(0deg);
    }

    100% {
      transform: rotate(360deg) translateX(calc(#{$circle_move_offset_sp})) rotate(-360deg);
    }

  }
}
//
//
//@include mix.max-media(var.$screen-sm){
//  @keyframes circle-move-anim{
//    0% {
//      transform: rotate(0deg) translateX(-55px) rotate(0deg);
//    }
//
//    100% {
//      transform: rotate(360deg) translateX(-55px) rotate(-360deg);
//    }
//  }
//}