@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.hero {
  position: relative;
  width: 100%;
  height: 56.51vw;
  @include mix.max-media(var.$screen-md){
    height: 216.6vw;
  }

  @include mix.max-media(var.$screen-sm){
  }
}

.top_heroSlider{
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

// 属性分け
// -------------------

#js_heroSlider_pc{
  display: block!important;
  @include mix.max-media(var.$screen-md){
    display: none!important;
  }
}

#js_heroSlider_sp{
  display: none!important;
  @include mix.max-media(var.$screen-md){
    display: block!important;
  }
}


.top_heroImg{
  width: 100%;
  max-width: 100%;
  max-height: 56.51vw;
  overflow: hidden;

  @include mix.max-media(var.$screen-md){
    max-height: 216.6vw;
  }

  img{
    height: 56.51vw;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    width: 100%;

    @include mix.max-media(var.$screen-md){
      height: 216.6vw;
    }

  }
}

//
// ------------------------------------------------------------

.topHero_headingGroup{
  position: absolute;
  top: 65%;
  left: 50%;
  transform : translate(-50%,-50%);
  width: function.px2vw(650,1434);
  //margin-top: 2vw;

  @include mix.max-media(var.$screen-md){
    top: 50%;
    width: function.px2per(345,390);
  }
}

.hero_heading{

  margin-bottom: 1.5vw;
  text-align: end;
  white-space: nowrap;
  letter-spacing: .085em;
  font-family: var.$font-family-base;
  font-size: 1.8vw;
  font-weight: bold;
  color: var.$brand-main;
  text-shadow: 3px 3px 3px rgba(255, 255, 255, 0.7);

  @include mix.max-media(var.$screen-md){
    font-size: 3.3vw;
    margin-bottom: 3vw;
  }

}

.hero_logo {
  width: 100%;
  margin-bottom: 40px
;
  img{
    width: 100%;
    max-width: 100%;
    filter: drop-shadow( 3px 3px 3px rgba(255, 255, 255, 0.7));
  }
}
