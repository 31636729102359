@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.headline01Group{
  background-color: rgba(255,255,255,.6);
  border-top: 2px solid var.$brand-main;
  border-bottom: 2px solid var.$brand-main;
  max-width: 100%;
  padding: 60px;

  @include mix.max-media(var.$screen-slg){
    padding: 32px;
  }

  @include mix.max-media(var.$screen-sm){
    padding: 24px 16px;
  }
}

.headline01Item{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 34px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 24px;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.headline01Item_date,
.headline01Item_contents{
  font-family: var.$font-family-base;
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  letter-spacing: .04em;

  @include mix.max-media(var.$screen-md){
    font-size: 14px;
  }
}

.headline01Item_date{
  position: relative;
  margin-right: 1em;
  &:after{
    position: absolute;
    display: block;
    content: '/';
    top: .8em;
    right: -1em;
    transform : translate(-50%,-50%);
  }
}

.headline01Item_contents{
  p{
    font-size: var.$font-size-base;

    @include mix.max-media(var.$screen-md){
      font-size: 14px;
    }
  }
}
