@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.footer{
  width: 100%;
}

.footer_inner{
  width: 100%;
  max-width: 100%;
  padding: 20px 0 36px;
  
  @include mix.max-media(var.$screen-slg){
    padding: 20px 0 20px;
  }
}


//fnav
// ============================================================

.fnav{
  width: 50%;
  margin: 0 auto 64px;

  @include mix.max-media(var.$screen-slg){
    display: none;
  }
}

.fnav_menuList{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
//
  width: 100%;
  max-width: 100%;
}

.fnav_menuItem{
  position: relative;
  margin-right: 2em;
  margin-left: .5em;

  &:first-child{
    margin-left: 0;
  }

  &:last-child{
    margin-right: 0;

    &:after{
      display: none;
    }
  }

  &:after{
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    height: 1.5em;
    top: 40%;
    right: -1.5em;
    transform : translate(-50%,-50%) rotate(20deg);
    background-color: #000000;
  }


  a{
    @include mix.setTextLinkColor(#000);
    font-family: var.$font-family-base;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: .06em;
    transition: .3s;

    &:active,
    &:link,
    &:visited,
    &:focus{
      color: #000000;
    }
    &:hover{
      text-decoration: none;
      opacity: .7;
      transition: .3s;
    }
  }
}

.footer_logo{
  max-width: 23%;
  margin: 0 auto;

  @include mix.max-media(var.$screen-md){
    max-width: 46%;
  }

  a{
    @include mix.transitionDelay();
    &:hover{
      @include mix.transitionDelay();
      opacity: .7;
    }
  }
}