@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//top_heading01関連
// ============================================================

.top_headingUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;

  @include mix.max-media(var.$screen-md){
    flex-flow: row nowrap;
    justify-content: start;
    margin-bottom: 8px;
  }
}

.topSectionNews_headingUnit{
  margin-bottom: function.px2em(14,10);
}

.top_panel01_headingUnit{
  justify-content: start;
  margin-bottom: 20px;

  &.isEnlarge_left{
    position: relative;
    z-index: 1;
    width: 115%;
    margin-left: -15%;

  }
}

.topSectionCompany_headingUnit{
  justify-content: start;
  margin-bottom: 56px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 18px;
  }

  @include mix.max-media(var.$screen-md){
    margin-bottom: 35px;
  }
}

.topSectionRecruitmenty_headingUnit{
  justify-content: start;
  margin-bottom: 1.5em;
  @include mix.max-media(var.$screen-md){
    margin-bottom: 1em;
  }
}



.top_heading01{
  font-family: Avenir;
  font-size: 28px;
  font-weight: 500;
  color: var.$heading-text-color;
  line-height: 1.1;
  letter-spacing: .06em;
  margin-right: 42px;

  @include mix.max-media(var.$screen-slg){
    font-size: 24px;
    margin-right: 22px;
  }

  @include mix.max-media(var.$screen-md){
    margin-right: 18px;
    white-space: nowrap;
  }

  @include mix.max-media(var.$screen-sm){
    font-size: 20px;
  }



}

.top_heading01_lead{
  position: relative;
  display: inline-block;
  font-family: var.$font-family-base;
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  letter-spacing: .16em;

  @include mix.max-media(var.$screen-md){
    white-space: nowrap;
    font-size: 14px;
  }

  &:before{
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    height: 1em;
    top: 50%;
    left: -.7em;
    transform : translate(-50%,-50%) rotate(25deg);
    background-color: #000000;
    
    @include mix.max-media(var.$screen-md){
      left: -.6em;
    }
  }
}

.topSectionRecruitmenty_heading01{
  color: var.$text-color-white;
  font-size: 42px;

}

.topSectionRecruitmenty_heading01_lead{
  color: var.$text-color-white;

  &:before{
    background-color: var.$text-color-white;
  }
}

//ロゴ入り見出し
// ============================================================

.top_heading02{
  font-family: var.$font-family-base;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.1;

  @include mix.max-media(var.$screen-md){
    font-size: 16px;
  }

  img{
    max-width: 19em;
    margin-right: 12px;

    @include mix.max-media(var.$screen-md){
      max-width: 15em;
    }

    @include mix.max-media(var.$screen-sm){
      max-width: 11.6em;
    }
  }
}

.topSectionCompany_heading02{
  margin-bottom: 34px;

  @include mix.max-media(var.$screen-md){
    margin-bottom: 21px;
  }
}