@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.textUnit{

  p{
    font-family: var.$font-family-base;
    font-size: var.$font-size-base;
    line-height:  1.8em;

    @include mix.max-media(var.$screen-md){
      font-size: 14px;
      line-height: 1.7;
    }
  }
}


//各セクション固有
// ============================================================

.top_panel01_textUnit{
  margin-bottom: 20px;
}

.topSectionCompany_textUnit{
  @include mix.max-media(var.$screen-lmd){
    margin-bottom: 32px;
  }
}

.topSectionRecruitmenty_textUnit{
  p{
    color: var.$text-color-white;
  }
}
